import React, { Component } from "react";
import { connect } from "react-redux";
import { loginWatcher } from "../../redux/actions/loginactioncreators";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";
import { Button } from "../fields/Button";

class ResendVerificationCode extends Component {
  handleResendVerificationCode = () => {
    this.props.loginWatcher(
      {
        username: this.props.username,
        password: this.props.password,
        flowStage: "RESEND_SMS_VERIFICATION_CODE"
      }
    )
  }

  render() {
    const bespokeResendVerificationCodeButtonClassname = "ml-1 mt-1.5 text-sm";
    return (
      <Button
        id="submitResend"
        name="submitResend"
        variant="text"
        className={bespokeResendVerificationCodeButtonClassname}
        onClick={this.handleResendVerificationCode}
      >
        <FormattedMessage id={"sms.resendButton"} defaultMessage={"Resend verification code"} />
      </Button>
    )
  }
}
ResendVerificationCode.propTypes = {
  loginWatcher: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  intl: PropTypes.objectOf(IntlProvider),
}
const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    password: state.loginForm.password,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResendVerificationCode));