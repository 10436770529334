import React, { Component } from "react";
import { FormControlLabel } from "@material-ui/core";
import { Checkbox } from "../fields/Checkbox";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { saveDevice } from "../../redux/actions/loginactioncreators"
import PropTypes from "prop-types";

class SaveDevice extends Component {
  handleSaveDevice = (_saveDeviceAction, value) => {
    //TODO Test this, might not work COR-1814
    this.props.saveDevice(value);
  }

  render() {
    return (
      <div className="p-3">
        <FormControlLabel
          control={
            <Checkbox
              name="saveDevice"
              onChange={this.handleSaveDevice}
              value='saveDevice'
            />
          }
          label={<FormattedMessage id={"mfa.saveDevice"} defaultMessage={"Remember this device for 30 days"} />}
        />
      </div>
    )
  }
}

SaveDevice.propTypes = {
  intl: PropTypes.objectOf(IntlProvider),
  saveDevice: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
    saveDevice: state.loginForm.saveDevice
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    saveDevice
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SaveDevice));
