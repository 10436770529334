import React, { Component } from "react";
import { Grid, Typography, withMobileDialog } from "@material-ui/core";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendTotpCode } from "../../redux/actions/loginactioncreators";
import { navigateToLogin } from "../../redux/helpers/navigators";
import SaveDevice from "./SaveDevice";
import PropTypes from "prop-types";
import { Button } from "../fields/Button";
import { TextField } from "../fields/TextField";

class MfaTotpCodeEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mfaCode: "",
    }
  }

  handleBackToUserNameEntry = () => {
    navigateToLogin();
  }

  handleOnChange = event => {
    let newState = this.state;
    newState.mfaCode = event.target.value;
    this.setState(newState);
  }
  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    try {
      this.props.sendTotpCode(this.state.mfaCode);
    } catch (err) {
      newState.loginErrorMessages = [err.message]
    }
    this.setState(newState);
  }

  render() {
    return (
      <main ariaLabel='Time-Based One-Time Password entry page'>
        <Grid item xs={12}>
          <Typography variant="h6">
            <FormattedMessage id={"mfa.enterCode"} defaultMessage={"Enter code"} />
          </Typography>
          <Typography>
            <FormattedMessage id={"totp.blurb"} defaultMessage={"Please use your Google Authenticator, or other Time-Based One-Time Password App to generate a code below."} />
          </Typography>
          <TextField
            autoFocus={true}
            onChange={this.handleOnChange}
            id="mfacode"
            name="mfacode"
            onSubmit={this.handleOnSubmit}
            fullWidth={true}
          />

          <Grid container className='justify-start'>
            <Grid item xs={12}>
              <SaveDevice />
            </Grid>
          </Grid>
          <Grid container className='justify-end'>
            <Grid item >
              <Button
                ariaLabel="Go back to username entry"
                id="backToUserNameEntry"
                name="backToUserNameEntry"
                onClick={this.handleBackToUserNameEntry}
                variant="outlined"
              >
                <FormattedMessage id={"login.backToUserNameEntry"} defaultMessage={"Back"} />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                ariaLabel="Submit verification code"
                id="submit"
                name="submit"
                onClick={this.handleOnSubmit}
              >
                <FormattedMessage id={"sms.verifyButton"} defaultMessage={"Verify"} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </main>
    )
  }
}

MfaTotpCodeEntry.propTypes = {
  intl: PropTypes.objectOf(IntlProvider),
  saveDevice: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  sendTotpCode: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    saveDevice: state.loginForm.saveDevice
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendTotpCode
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withMobileDialog()(MfaTotpCodeEntry)));
