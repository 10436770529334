import React, { Component } from "react";
import { List, ListItem } from "@material-ui/core";
import { IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import PropTypes from "prop-types";
export class ErrorFormatter extends Component {
  render() {
    const {formatMessage} = this.props.intl;
    const errorFormatterPaddingClassname = "pb-4";
    const bespokeErrorFormatterTextClassname = "text-error-800 text-sm";
    return (
      <div className={errorFormatterPaddingClassname}>
        {this.props.validationErrors.length > 0 &&
        <List id={this.props.id || null}>
          {this.props.validationErrors
          .map((validationError)=>{
            // Rather than throw runtime errors, allow bespoke object format alongside plain messages
            if (validationError?.message?.id !== undefined) {
              return validationError;
            } else if (validationError?.id) {
              return {message: validationError}
            }
            console.warn("Validation error did not contain a localizable message.")
          })
          .filter((error)=>error?.message?.id)
          .map((validationError, index) => {
            return (
              <span>
              <ListItem key={index}>
                <p className={bespokeErrorFormatterTextClassname}>
                  {formatMessage(validationError.message, validationError.context)}
                </p>
              </ListItem>
              </span>
            )
          })
          }
        </List>
        }
      </div>
    )
  }
}

ErrorFormatter.propTypes = {
  intl: PropTypes.objectOf(IntlProvider),
  classes: PropTypes.object.isRequired,
  validationErrors: PropTypes.array,
  id: PropTypes.string,

}
const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
  }
}
export default connect(mapStateToProps)(injectIntl(ErrorFormatter));
