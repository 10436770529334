import {all} from "redux-saga/effects"
import {watchGetProviderAsync} from "./sagas/getprovider";
import {watchResetPasswordAsync} from "./sagas/resetpassword";
import {watchLoginAsync} from "./sagas/clientSideLogin";
import {watchLegacyLoginAsync} from "./sagas/legacyLogin";
import {watchLoginSuccessAsync} from "./sagas/createsession";
import {watchValidatePasswordAsync} from "./sagas/validatePassword";
import { watchAuthResponse } from "./sagas/handleAuthResponse";
import { watchTotpCodeAsync } from "./sagas/sendtotpcode";
import { watchSmsMfaCodeAsync } from "./sagas/sendSmsMfaCode";
import { watchChangePasswordAsync } from "./sagas/changepassword";
import { watchMfaChoiceAnswer } from "./sagas/chooseMfaType";
import { handleErrorWatcher } from "./sagas/handleError";


export default function* rootSaga() {
  yield all([
    watchGetProviderAsync(),
    watchLoginAsync(),
    watchLegacyLoginAsync(),
    watchLoginSuccessAsync(),
    watchResetPasswordAsync(),
    watchValidatePasswordAsync(),
    watchAuthResponse(),
    watchMfaChoiceAnswer(),
    watchTotpCodeAsync(),
    watchSmsMfaCodeAsync(),
    watchChangePasswordAsync(),
    handleErrorWatcher(),
  ])
}