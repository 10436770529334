export function navigateToDashboard() {
  window.location = '/dashboard' + window.location.search;
}

export function navigateToApplication() {
  window.location = '/application' + window.location.search;
}

export function navigateToAuthorize() {
  window.location = '/oauth2/authorize' + window.location.search;
}

export function navigateToLogin() {
  window.location = '/oauth2/login' + window.location.search;
}

export function navigateToLogout() {
  window.location = '/oauth2/logout' + window.location.search;
}