import { put, select, takeLatest } from "redux-saga/effects"
import { handleAuthResponse, handleError } from "../actions/loginactioncreators"
import getCognitoUser from "../helpers/getCognitoUser";
import actiontypes from "../actions/actiontypes";
import getCognitoIdentityCallbacks from "../../lib/cognitoIdentityCallbacks";

export function* answerMfaChoiceChallenge(action) {
    try {
      const cognitoUser = yield select(getCognitoUser);
      const cognitoResponse = yield new Promise((resolve,reject) => {
        return cognitoUser.sendMFASelectionAnswer(
          action.payload,
          getCognitoIdentityCallbacks(resolve, reject),
        )
      });
      yield put(handleAuthResponse(cognitoResponse));
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* watchMfaChoiceAnswer() {
  yield takeLatest(actiontypes.MFA_SELECT_TYPE, answerMfaChoiceChallenge);
}
