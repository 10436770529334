import createSagaMiddleware from 'redux-saga'
import {createLogger} from 'redux-logger';
import {applyMiddleware, createStore} from 'redux';
import searchApp from './reducers';
import saga from './saga'
import {load, save} from "redux-localstorage-simple"
import statesToSave from './statesToSave'
import GoogleAnalyticsGtag, { trackEvent } from '@redux-beacon/google-analytics-gtag';
import {createMiddleware} from 'redux-beacon';
import ActionTypes from './actions/actiontypes';

const deepmerge = require('deepmerge');
const sagaMiddleware = createSagaMiddleware();
const initialState = {
  failedLogins: 0,
  flowStage: "LOGIN",
  challenge: {},
  successMessages: [],
  loginErrorMessages: [],
  mfaComplete: [],
  totpSpinning: false,
  username: '',
  provider: '',
  passwordErrors: [],
  remembered: false,
  device: {},
  clientId: '',
  saveDevice: false,
  mfa: {
    mobileNumber: {
      value: '',
      enabled: false,
    },
    totp: {
      enabled: false,
    }
  }
}

function buildStore(options) {
  let loadedState = {};
  let loadedIntl = {};
  try {
    const statesFromLS = load({states: statesToSave, disableWarnings: true});
    if (statesFromLS.loginForm) {
      if (statesFromLS.loginForm.remembered) {
        loadedState.username = statesFromLS.loginForm.username;
        loadedState.provider = statesFromLS.loginForm.provider;
        loadedState.remembered = statesFromLS.loginForm.remembered;
      }
      if (statesFromLS.loginForm.device) {
        loadedState.device = statesFromLS.loginForm.device;
      }
    }
    if (statesFromLS.intl && statesFromLS.intl.locale) {
      loadedIntl.locale = statesFromLS.intl.locale;
    }
  } catch (err) {
    // Failed to read localStorage
    // Under some circumstances redux-localstorage-simple will write a record as undefined and
    // will throw JSON.parse errors trying to read it. Hopefully the next save will fix things.
  }

  const ga = GoogleAnalyticsGtag();
  const eventsMap = {
    [ActionTypes.USER_LOGIN]: trackEvent(function(action) {
      let label = 'LOGIN_WITH_PASSWORD';

      if(action.payload.flowStage) {
        label = action.payload.flowStage;
        if(action.payload.flowStage === 'CHALLENGE_MFA_SETUP' || action.payload.flowStage === 'CHALLENGE') {
          if(action.payload.challengeResponse && action.payload.challengeResponse.challengeName) {
            label += '[' + action.payload.challengeResponse.challengeName + ']';
          }
        }
      }
      return {
        category: "User Login [" + initialState.clientId + "]",
        action: "Login Attempt",
        label: label,
      }
    }),
    [ActionTypes.USER_LOGIN_ERROR]: trackEvent(action => ({
      category: "User Login [" + initialState.clientId + "]",
      action: "Login Failed",
      label: action.payload
    })),
    [ActionTypes.USER_LOGIN_HANDLE_CHALLENGE]: trackEvent(action => ({
      category: "User Login [" + initialState.clientId + "]",
      action: "Login Challenge Request",
      label: action.payload.challenge.challengeName,
    })),
  };
  const googleAnalyticsMiddleware = createMiddleware(eventsMap, ga)
  let middleWares = [save({states: statesToSave}), sagaMiddleware, googleAnalyticsMiddleware];
  if (options.logger) {
    const loggerMiddleware = createLogger();
    middleWares = [...middleWares, loggerMiddleware]
  }
  const store = createStore(
    searchApp,
    deepmerge.all([{loginForm: initialState}, options, {loginForm: loadedState}, {intl: loadedIntl}]),
    applyMiddleware(
      ...middleWares
    )
  );
  sagaMiddleware.run(saga);
  return store;
}

export default buildStore;
