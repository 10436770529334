import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { TextField } from "../fields/TextField";
import { Button } from "../fields/Button";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  loginWatcher,
  mfaSetSmsNumber
} from "../../redux/actions/loginactioncreators";
import phone from "phone";
import PropTypes from "prop-types";
import challengeShape from "../../proptypes/challengeShape";
import flowStages from "../../flowStages";
import { InputPhone } from "@sterling-react/input";
import ExpansionPanel, { ExpansionPanelDetails, ExpansionPanelSummary } from '@sterling-react/expansion-panel';
import { Check } from '@material-ui/icons';


class MfaMobilePhone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileNumber: "",
      mobileNumberValid: false,
      verificationCode: "",
      verificationCodeValid: false,
    }
  }
  handleChange = (event) => {
    this.props.mfaSetSmsNumber(event?.target?.value);
    this.setState({
      mobileNumberValid: true
    });
  };
  handleClickNext = () => {
    this.props.loginWatcher(
      {
        flowStage: flowStages.CHALLENGE_MFA_SETUP,
        challengeResponse: {
          session: this.props.challenge.session,
          challengeName: this.props.challenge.challengeName,
          step: "MFA_SETUP_SMS",
          challengeParameters: {
            USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
            MFA_CONFIG: {
              PHONE_NUMBER: phone(this.props.mfa.mobileNumber.value)[0]
            },
            PASSWORD: this.props.password
          }
        }
      }
    )
  }

  handleResendVerificationCode = () => {
    this.props.loginWatcher(
      {
        flowStage: flowStages.CHALLENGE_MFA_SETUP,
        challengeResponse: {
          session: this.props.challenge.session,
          challengeName: this.props.challenge.challengeName,
          step: "MFA_SETUP_SMS",
          challengeParameters: {
            USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
            RESEND: "true",
            MFA_CONFIG: {
              PHONE_NUMBER: phone(this.props.mfa.mobileNumber.value)[0]
            },
            PASSWORD: this.props.password
          }
        }
      }
    )
  }
  validateVerificationCode(verificationCode) {
    if (verificationCode.length === 6) {
      return true;
    }
    return false;
  }

  handleChangeVerify = event => {
    let valid = false;
    if (event.target.value.length === 6) {
      valid = this.validateVerificationCode(event.target.value);
    }
    this.setState({
      verificationCode: event.target.value,
      verificationCodeValid: valid
    });
  }
  handleClickVerify = () => {
    const params = {
      flowStage: flowStages.CHALLENGE_MFA_SETUP,
      challengeResponse: {
        session: this.props.challenge.session,
        challengeName: this.props.challenge.challengeName,
        step: "MFA_SETUP_SMS_VERIFY",
        challengeParameters: {
          USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
          MFA_CONFIG: {
            VERIFICATION_CODE: this.state.verificationCode
          },
          PASSWORD: this.props.password
        }
      }
    };
    this.props.loginWatcher(
      params
    )
  }

  render() {
    const readyToVerify = this.props.challenge.step === "MFA_SETUP_SMS_VERIFY";
    const buttonDisabled = this.props.challenge.step === "MFA_SETUP_SMS_VERIFY" || !this.state.mobileNumberValid;
    const bespokeSmsMfaExpansionPanelClassname = "mx-auto w-[600px] rounded-md max-w-full py-1 border";
    return (
      <div id="mobileNumber">
      <ExpansionPanel className={bespokeSmsMfaExpansionPanelClassname}>
        <ExpansionPanelDetails
          className='px-2 my-4 bg-#f5f5f5 duration-300'
        >
          <ExpansionPanelSummary
            className='bg-inherit px-5 py-3 text-lg'
          >
            <FormattedMessage id={"mfaSetup.smsSetup"} defaultMessage={"Mobile Phone Text Message"} />
            {this.props.complete && <Check />}
          </ExpansionPanelSummary>
          {!this.props.complete && <React.Fragment>
            <Grid container>
              <Grid item xs={8}>
                <InputPhone
                  id="mobileNumberEntry"
                  name="mobileNumberEntry"
                  onChange={this.handleChange.bind(this)}
                  testId="phone-input"
                  value={this.props.mfa.mobileNumber.value}
                  setValueCallback={this.handleChange.bind(this)}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={3} className='content-end'>
                <Button
                  fullWidth={true}
                  disabled={buttonDisabled}
                  id="phoneNumberNext"
                  name="phoneNumberNext"
                  onClick={this.handleClickNext}
                >
                  <FormattedMessage id={"mfaSetup.smsSetupPhoneNext"} defaultMessage={"Next"} />
                </Button>
              </Grid>
            </Grid>
            {readyToVerify &&
              <React.Fragment>
                <Grid container className="mt-4">
                  <Grid item xs={12}>
                    <Typography>
                      <FormattedMessage id={"mfaSetup.smsSetupPhoneResendBlurb"} defaultMessage={"Resend verification code"} />
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      id="submitResend"
                      name="submitResend"
                      onClick={this.handleResendVerificationCode}
                      variant="text"
                      className='ml-1 text-sm'
                    >
                      <FormattedMessage id={"sms.resendButton"} defaultMessage={"Resend verification code"} />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container className="mt-4">
                  <Grid item xs={8} >
                    <TextField
                      autoFocus={true}
                      error={this.state.totpCodeError}
                      wrapperClassName='m-0'
                      onChange={this.handleChangeVerify}
                      label={<FormattedMessage id={"mfaSetup.smsSetupPhoneVerificationCode"} defaultMessage={"Verification Code"} />}
                      id="verificationCode"
                      name="verificationCode"
                    ></TextField>
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={3} className='content-end'>
                    <Button
                      fullWidth={true}
                      disabled={!this.state.verificationCodeValid}
                      id="verificationCodeVerify"
                      name="verificationCodeVerify"
                      onClick={this.handleClickVerify}
                    >
                      <FormattedMessage id={"mfaSetup.smsSetupPhoneVerify"} defaultMessage={"Enter the verification code you received."} />
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }
          </React.Fragment>}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      </div>
    )
  }
}

MfaMobilePhone.propTypes = {
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  loginWatcher: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  password: PropTypes.string,
  complete: PropTypes.bool,
  mfa: PropTypes.object,
  mfaSetSmsNumber: PropTypes.func.isRequired
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    userInfo: state.loginForm.userInfo,
    username: state.loginForm.username,
    password: state.loginForm.password,
    deviceKey: state.loginForm.deviceKey,
    mfa: state.loginForm.mfa
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher,
    mfaSetSmsNumber
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MfaMobilePhone));
