import {combineReducers} from 'redux';
import {intlReducer} from 'react-intl-redux';
import actiontypes from "./actions/actiontypes";



function loginForm(state, action) {
  let newState = {...state};
  newState.loginErrorMessages = [];
  newState.successMessages = [];
  newState.isFetching = false;
  switch (action.type) {
    case actiontypes.USER_GET_PROVIDER:
      newState.isFetching = true;
      return {...newState, username: action.payload.username};
    case actiontypes.SET_S1_TRANSPORT:
      return {...newState, s1TransportUser: action.payload};
    case actiontypes.SET_PROVIDER:
      newState.isFetching = false;
      return {...newState, provider: action.payload};
    case actiontypes.USER_LOGIN:
      if (action.payload.username && action.payload.password) {
        newState.username = action.payload.username.trim().toLowerCase();
        newState.password = action.payload.password.trim();
        newState.passwordErrors = [];
      }
      newState.isPreAuth = true;
      newState.isFetching = true;
      return newState;
    case actiontypes.USER_LOGIN_LEGACY:
      if (action.payload.username && action.payload.password) {
        newState.username = action.payload.username.trim().toLowerCase();
        newState.password = action.payload.password.trim();
        newState.passwordErrors = [];
      }
      newState.isFetching = true;
      return newState;
    case actiontypes.USER_LOGIN_ERROR:
      return {
        ...newState,
        loginErrorMessages: action.payload,
      }
    case actiontypes.USER_LOGIN_ERROR_FAILED_MIGRATION:
      return {
        ...newState,
        failedLogins: state.failedLogins + 1
      }
    case actiontypes.USER_LOGIN_MFA:
      return {
        ...newState,
        flowStage: 'CHALLENGE_MFA'
      };
    case actiontypes.MFA_REQUIRED:
      newState.totpSpinning = false;
      newState.flowStage = action.payload.challengeName;
      newState.cognitoUser = action.payload.cognitoUser;
      return newState;
    case actiontypes.USER_LOGIN_SUCCESS:
      newState.isPreAuth = false;
      newState.isFetching = true;
      newState.authToken = action.payload.idToken.jwtToken
      newState.refreshToken = action.payload.refreshToken.token
      return newState;
    case actiontypes.SAVE_COGNITO_USER:
      newState.cognitoUser = action.payload;
      return newState;
    case actiontypes.USER_LOGIN_HANDLE_PREAUTH_CHALLENGE:
      newState.flowStage = action.payload.challengeName;
      newState.totpSpinning = false;
      if (action.payload.responseData.challengeName || action.payload.responseData.challengeParameters) {
        newState.challenge = action.payload.responseData
      }
      if (action.payload.responseData && action.payload.responseData.userAttributes) {
        newState.UserAttributes = action.payload.responseData.userAttributes
      }
      return newState;
    case actiontypes.USER_LOGIN_HANDLE_CHALLENGE:
      if (action.payload.userInfo) {
        newState.userInfo = {
          UserAttributes: action.payload.userInfo.UserAttributes.reduce(function (object, item) {
            object[item.Name] = item.Value;
            return object;
          }, {})
        }
      }
      newState.flowStage = action.payload.challenge.challengeName;
      newState.challenge = action.payload.challenge;
      if (action.payload.challenge.challengeParameters['SECRET_CODE']) {
        newState.secretCode = action.payload.challenge.challengeParameters['SECRET_CODE'];
      }
      if (action.payload.challenge.challengeParameters['TOTP_SESSION']) {
        newState.totpSession = action.payload.challenge.challengeParameters['TOTP_SESSION'];
      }
      newState.totpSpinning = false;
      return newState;
    case actiontypes.USER_LOGIN_FLOW_STAGE:
      newState.flowStage = action.payload.flowStage;
      return newState;
    case actiontypes.USER_RESET_PASSWORD: {
      newState.isFetching = true;
      return {...newState};
    }
    case actiontypes.USER_CHANGE_PASSWORD: {
      newState.password = action.payload.newPassword;
      return {...newState};
    }
    case actiontypes.USER_RESET_PASSWORD_RESPONSE: {
      newState.flowStage = action.payload.flowStage;
      newState.step = action.payload.flowStage;
      newState.challenge = action.payload.challenge;
      newState.isFetching = false;
      if (action.payload.status) {
        if (action.payload.status === 'success') {
          newState.successMessages.push(action.payload.message);
        }
        if (action.payload.status === 'error') {
          newState.loginErrorMessages.push(action.payload.message);
        }
      }
      return {...newState};
    }
    case actiontypes.USER_RESET_PASSWORD_CANCEL: {
      newState.flowStage = 'LOGIN'
      delete newState.step;
      return {...newState};
    }
    case actiontypes.TOTP_SPINNING: {
      newState.totpSpinning = true;
      return {...newState};
    }
    case actiontypes.REMOVE_PROVIDER:
      newState.provider = '';
      newState.username = '';
      newState.passwordErrors = [];
      return {...newState};
    case actiontypes.SET_USERNAME:
      newState.username = action.payload;
      newState.password = '';
      return {...newState};
    case actiontypes.SET_PASSWORD:
      newState.password = action.payload.trim();
      return {...newState}
    case actiontypes.SET_PASSWORD_ERRORS:
      newState.passwordErrors = action.payload;
      return {...newState};
    case actiontypes.REMEMBER_ME:
      if(!newState.remembered) {
        newState.remembered = true;
      } else {
        newState.remembered = false
      }
      return {...newState};
    case actiontypes.SET_DEVICE_DATA: {
      // TODO Device saving COR-1814
      if(state.saveDevice) {
        const username = action.payload.username.toLowerCase();
        newState.device[username] = {
          deviceKey: action.payload.deviceKey,
          deviceGroupKey: action.payload.deviceGroupKey,
          devicePassword: action.payload.devicePassword,
          expires: action.payload.expires
        }
      }
      return {...newState}
    }
    case actiontypes.SAVE_DEVICE: {
      // TODO Device saving COR-1814
      newState.saveDevice = action.payload === true;
      return {...newState};
    }
    case actiontypes.MFA_SET_SMS_NUMBER: {
      newState.mfa.mobileNumber.value = action.payload;
      return {...newState};
    }
    case actiontypes.MFA_ENABLE_CHOICE: {
      newState.mfa[action.payload.id].enabled = action.payload.value;
      return {...newState};
    }
    case actiontypes.SET_MFA_TYPE_SETUP_COMPLETE: {
      newState.mfaComplete.push(action.payload);
      return {...newState};
    }

    default:
      return {...newState}
  }
}

export default combineReducers({
  intl: intlReducer,
  loginForm,
});