import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "../fields/Button";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import {connect} from "react-redux";
import MfaMobilePhone from "./MfaMobilePhone"
import MfaTotp from "./MfaTotp";
import { bindActionCreators } from "redux";
import { mfaSetupComplete } from "../../redux/actions/loginactioncreators";
import challengeShape from "../../proptypes/challengeShape";
import { navigateToLogin } from "../../redux/helpers/navigators";
import PropTypes from "prop-types";

class MfaSetup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mobileNumber: "",
    }
  }

  handleBackToUserNameEntry = () => {
    navigateToLogin();
  }

  handleChange = value => {
    this.setState({
      mobileNumber: value,
    });
  };

  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    this.setState(newState);
    this.props.mfaSetupComplete();
  }

  render() {
    const {mfaComplete} = this.props;
    const totpEnabled = mfaComplete.includes("TOTP");
    const smsEnabled = mfaComplete.includes("SMS");
    return (
      <main>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
                <FormattedMessage id={"login.mfaSetupTitle"} defaultMessage={"Multi-Factor Authentication Setup"} />
            </Typography>
            <Typography>
              <FormattedMessage id={"login.mfaSetupTitle"} defaultMessage={"Multi-Factor Authentication Setup"} />
            </Typography>
            <br/>
            <MfaMobilePhone complete={smsEnabled}></MfaMobilePhone>
            <MfaTotp complete={totpEnabled}></MfaTotp>

            {/*{!this.props.challenge.challengeParameters['REQUIRED'] &&*/}
            {/*<Button variant="contained" color="primary">{formatMessage(messages.mfaSkip)}</Button>*/}
            {/*}*/}
          </Grid>

        </Grid>

        <Grid container className="justify-end pt-3">
          <Grid item >
            <Button
              id="backToUserNameEntry"
              name="backToUserNameEntry"
              onClick={this.handleBackToUserNameEntry}
              variant="outlined"
            >
              <FormattedMessage id={"login.backToUserNameEntry"} defaultMessage={"Back"} />
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="mfaSetupComplete"
              name="mfaSetupComplete"
              onClick={this.handleOnSubmit}
              disabled={mfaComplete.length < 1}
            >
              <FormattedMessage id={"login.mfaComplete"} defaultMessage={"Complete Setup"} />
            </Button>
          </Grid>
        </Grid>
      </main>
    )
  }
}
MfaSetup.propTypes = {
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  mfaSetupComplete: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  mfaComplete: PropTypes.array
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    extraData: state.loginForm.extraData,
    userInfo: state.loginForm.userInfo,
    mfaComplete: state.loginForm.mfaComplete
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    mfaSetupComplete
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MfaSetup));
