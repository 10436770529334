
import AuthenticationHelper from "amazon-cognito-identity-js/lib/AuthenticationHelper";
import * as CryptoJS from 'crypto-js';
import DateHelper from 'amazon-cognito-identity-js/lib/DateHelper';
var Buffer = require('buffer/').Buffer
export default class AuthHelper {
  constructor(deviceGroupKey) {
    this.authenticationHelper = new AuthenticationHelper(deviceGroupKey);
  }
  getLargeAValue() {
    let that = this;
    return new Promise((resolve, reject) => {
      that.authenticationHelper.getLargeAValue(function (error, srpA) {
        if (error) {
          reject(error)
        } else {
          resolve(srpA)
        }
      })
    })
  }

  getPasswordAuthenticationKey(params) {
    let that = this;
    return new Promise((resolve, reject) => {
      that.authenticationHelper.getPasswordAuthenticationKey(params.deviceKey, params.devicePassword, params.serverBValue, params.salt, function (error, hkdf) {
        if (error) {
          reject(error)
        } else {
          const dateHelper = new DateHelper();
          const dateNow = dateHelper.getNowString();

          const message = CryptoJS.lib.WordArray.create(
            Buffer.concat([
              Buffer.from(params.deviceGroupKey, 'utf8'),
              Buffer.from(params.deviceKey, 'utf8'),
              Buffer.from(params.secretBlock, 'base64'),
              Buffer.from(dateNow, 'utf8'),
            ])
          );
          const key = CryptoJS.lib.WordArray.create(hkdf);
          const signatureString = CryptoJS.enc.Base64.stringify(CryptoJS.HmacSHA256(message, key));

          const challengeResponses = {};

          challengeResponses.USERNAME = params.username;
          challengeResponses.PASSWORD_CLAIM_SECRET_BLOCK = params.secretBlock;
          challengeResponses.TIMESTAMP = dateNow;
          challengeResponses.PASSWORD_CLAIM_SIGNATURE = signatureString;
          challengeResponses.DEVICE_KEY = params.deviceKey;
          resolve(challengeResponses);
        }
      })
    })
  }
}