class CognitoPasswordValidator {

  /**
   * @param {number} minimumLength
   * @param {number} maximumLength
   * @param {boolean} requireNumbers
   * @param {boolean} requireSpecialCharacters
   * @param {boolean} requireUppercase
   * @param {boolean} requireLowercase
   */
  constructor(minimumLength, maximumLength, requireNumbers, requireSpecialCharacters, requireUppercase, requireLowercase) {
    this.minimumLength = minimumLength > 6 ? minimumLength : 6;
    this.maximumLength = maximumLength < 99 ? maximumLength : 99;
    this.requireNumbers = requireNumbers;
    this.requireSpecialCharacters = requireSpecialCharacters;
    this.requireUppercase = requireUppercase;
    this.requireLowercase = requireLowercase;
  }

  /**
   * @param {string} password
   * @returns {Array}
   */
  validatePasswordFormat(password) {
    const errors = [];
    if (password.length < this.minimumLength) {
      errors.push({
        message: {
          id:"password.minLength",
          defaultMessage:"Password must have a minimum length of {minLength}",
        }, 
        context: {minLength: this.minimumLength}});
    }
    if (password.length > this.maximumLength) {
      errors.push({
        message: {
          id: "password.maxLength",
          defaultMessage: "Password must have a maximum length of {maxLength}",
        }, 
        context: {maxLength: this.maximumLength}});
    }
    if (this.requireNumbers && password.search(/\d/) === -1) {
      errors.push({
        message: {
          id: "password.numeric",
          defaultMessage: "Password must contain at least one numeric character",
        },
        context: {}});
    }
    if (this.requireUppercase && password.search(/[A-Z]/) === -1) {
      errors.push({
        message: {
          id: "password.upperCase",
          defaultMessage: "Password must contain at least one uppercase letter",
        },
        context: {}});
    }
    if (this.requireLowercase && password.search(/[a-z]/) === -1) {
      errors.push({
        message: {
          id: "password.lowerCase",
          defaultMessage: "Password must contain at least one lowercase letter",
        },
        context: {}});
    }
    if (this.requireSpecialCharacters && password.search(/[\^$*.[\]{}()?\-"!@#%&/\\,><':;|_~`]/) === -1) {
      errors.push({
        message: {
          id: "password.special",
          defaultMessage: "Password must contain at least one special character {specialCharacters}",
        },
        // eslint-disable-next-line quotes
        context: {specialCharacters: '^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ `'}
      });
    }
    return errors;
  }

  /**
   * @param {string} newPassword - new password value
   * @param {string} match - other password to compare against
   * @param {string} oldPassword - current password to be changed
   * @returns {Array}
   */
  validatePasswordRules(newPassword, match, oldPassword ) {
    const errors = [];

    if (newPassword && oldPassword && match) {
      if (oldPassword === newPassword || oldPassword === match) {
        errors.push({
          message: {
            id: "password.mustBeDifferent",
            defaultMessage: "New password must be different than current password",
        }, context: {}});
      }
    }

    if (newPassword && match) {
      if (newPassword.length > 4 && match.length > 4 && newPassword !== match) {
        errors.push({
          message: {
            id: "login.changePasswordPasswordsDoNotMatch",
            defaultMessage: "Passwords do not match",
          }, context: {}});
      }
    }

    return errors;
  }
}

export default CognitoPasswordValidator;