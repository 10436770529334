  /**
   * The loginClientId differs from clientId, at this time there is 1 to 1 mapping to each environment.
   * 
   * Client-side logins require there be no client secret, lest we need to have the secret client-side.
   * 
   * @returns {string} Client id used for login purposes
   */
export default function getloginClientId(state) {
  if (state.loginForm && state.loginForm.loginClientId) {
    return state.loginForm.loginClientId;
  }
}