// todo: consolidate this form and the one in react-sso-landing

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { loginSetFlowStageWatcher, loginWatcher } from "../../redux/actions/loginactioncreators";
import { bindActionCreators } from "redux";
import { Grid, Typography } from "@material-ui/core";
import * as EmailValidator from "email-validator";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import challengeShape from "../../proptypes/challengeShape";
import userInfoShape from "../../proptypes/userInfoShape";
import PropTypes from "prop-types";
import flowStages from "../../flowStages";
import { TextField } from "../fields/TextField";
import { Button } from "../fields/Button";

export class ChangeEmailAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props.userInfo?.UserAttributes?.email,
    }
  }

  handleChangeEmail = event => {
    const email = event.target.value;

    this.setState({email: email});
    this.setState({emailValid: this.validateEmail(email)});
  }
  handleSubmit = () => {
    this.props.loginWatcher({
      flowStage: flowStages.CHANGE_EMAIL_ADDRESS,
      username: this.props.username,
      challengeResponse: {
        session: this.props.challenge.session,
        challengeName: this.props.challenge.challengeName,
        challengeParameters: {
          USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
          NEW_EMAIL: this.state.email,
        }
      }
    });
  }
  handleSkip = () => {
    this.props.loginSetFlowStageWatcher({
      flowStage: flowStages.SKIP_EMAIL_NOT_VERIFIED,
    })
  }

  validateEmail(email) {
    if (!email) {
      return false;
    }
    return EmailValidator.validate(email);
  }

  render() {
    return (
      <Fragment>
        <Grid container className="justify-center">
          <Grid item xs={12}>
            <Typography variant="body1">{
              <FormattedMessage id={"login.changeEmailBlurb"} defaultMessage={"Your email address is in use by a different login.  Please change your email address.  A confirmation email will be sent to the new address with a code."} />
            }</Typography>
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="emailAddress"
              name="emailAddress"
              autoFocus={true}
              value={this.state.email}
              label={<FormattedMessage id={"login.emailAddress"} defaultMessage={"Email Address"} />}
              onChange={this.handleChangeEmail}
            />
          </Grid>
        </Grid>
        <br/>
        <Grid container className="justify-end">
          <Grid item>
            <Button
              id="skip"
              name="skip"
              onClick={this.handleSkip}
              variant="outlined"
            >
              <FormattedMessage id={"login.skipEmailSubmit"} defaultMessage={"Skip This Step"} />
            </Button>
          </Grid>
          <Grid item >
            <Button
              id="submit"
              name="submit"
              disabled={!this.validateEmail(this.state.email)}
              onClick={this.handleSubmit}
            >
              <FormattedMessage id={"login.changeEmailSubmit"} defaultMessage={"Submit"} />
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

ChangeEmailAddress.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string,
  loginWatcher: PropTypes.func.isRequired,
  userInfo: userInfoShape,
  intl: PropTypes.objectOf(IntlProvider),
  challenge: challengeShape,
  loginSetFlowStageWatcher: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    userInfo: state.loginForm.userInfo,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null,
    username: state.loginForm.username,
  }
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher,
    loginSetFlowStageWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ChangeEmailAddress));