import React, {Component} from 'react';
import PropTypes from 'prop-types';

export default class Hyperlink extends Component {
  render() {
    const {innerRef, to, ...rest} = this.props;
    return (
      <a {...rest} href={to} ref={innerRef}>{this.props.children}</a>
    )
  }
}

Hyperlink.propTypes = {
  innerRef: PropTypes.object.isRequired,
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired
}