import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { IconButton } from "@sterling-react/button";
import { InputAdornment } from "@sterling-react/input";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { bindActionCreators } from "redux";
import {
  getProviderWatcher,
  setUsername
} from "../../redux/actions/loginactioncreators";
import { FormattedMessage, injectIntl } from "react-intl";
import providerShape from "../../proptypes/providerShape";
import PropTypes from "prop-types";
import ErrorFormatter from "../ErrorFormatter";
import { TextField } from "../fields/TextField";

const COGNITO_MAX_USERNAME_LENGTH = 128

export class UsernameEntry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      validationErrors: {
        username: false,
        text: []
      }
    }
  }
  clearError() {
    this.setState({
      ...this.state,
      validationErrors: {
        username: false,
        text: []
      }
    })
  }
  setError(errors) {
    if (errors instanceof Array) {
      this.setState({ ...this.state, validationErrors: { username: true, text: errors } });
    } else {
      this.setState({ ...this.state, validationErrors: { username: true } });
    }
  }
  handleGetProvider = () => {
    const username = this.props.username.trim();
    const usernameValid = username.match(/[\p{L}\p{M}\p{S}\p{N}\p{P}]+/u)?.[0].length === username.length;
    if (usernameValid) {
      this.props.getProviderWatcher({ username });
      return;
    }
    let errors = [];
    errors.push({
      id: "invalidUsername",
      defaultMessage: "This username appears invalid, please try again",
    })
    if (username.match(/\s/)) {
      errors.push({
        id:"usernameContainsSpaces",
        defaultMessage:"The username contains spaces",
      })
    }
    this.setError(errors);
  }

  handleUsernameChange = event => {
    this.clearError();
    this.props.setUsername(event.target.value);
  }

  render() {
    const usernameError = !this.props.provider && (this.state.validationErrors.username || this.props.loginErrorMessages.length > 0);
    const bespokeUsernameEntryFieldClassname = "text-primary-500 disabled:cursor-default";
    const bespokeUsernameEntryInputAdornmentIconClassname = "text-gray-800 disabled:opacity-50 shadow-none disabled:pointer-events-none w-8 h-8 bg-transparent";
    return (
      <Fragment>
        <TextField
          error={usernameError}
          onChange={this.handleUsernameChange}
          onSubmit={this.handleGetProvider}
          id="username"
          label={<FormattedMessage id={"login.username"} defaultMessage={"Username"} />}
          value={this.props.username}
          maxLength={COGNITO_MAX_USERNAME_LENGTH}
          className={bespokeUsernameEntryFieldClassname}
          disabled={this.props.fetching || this.props.provider}
          adornment={!this.props.provider &&
            <InputAdornment id="getProvider" position='end' className={usernameError ? "hidden" : ""}>
              <IconButton
                id="getProviderButton"
                disabled={!(this.props.username) || this.props.username.length === 0}
                onClick={this.handleGetProvider}
                className={bespokeUsernameEntryInputAdornmentIconClassname}
              >
                <ArrowForwardIcon />
              </IconButton>
            </InputAdornment>
          }
        />
        <ErrorFormatter id="usernameEntryErrors" validationErrors={this.state.validationErrors.text ?? []} />
        {/* Simply putting this text into source file enables correct iconButton placement 🤯: -translate-x-1/2 */}
      </Fragment>
    )
  }
}

UsernameEntry.propTypes = {
  setUsername: PropTypes.func.isRequired,
  getProviderWatcher: PropTypes.func.isRequired,
  username: PropTypes.string,
  classes: PropTypes.object.isRequired,
  provider: providerShape,
  loginErrorMessages: PropTypes.array,
}
const mapStateToProps = state => {
  return {
    fetching: state.loginForm.isFetching ?? false,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    username: state.loginForm.username,
    provider: state.loginForm.provider
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    getProviderWatcher,
    setUsername
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UsernameEntry));
