import SterlingCheckbox from "@sterling-react/checkbox";
import PropTypes from 'prop-types';
import { Component } from "react";
import { twMerge } from "tailwind-merge";
/**
 * Sterling checkbox has a hard-coded left margin (ml-4).
 */
export class Checkbox extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const bespokeCheckboxClassname = "align-center w-4 h-4 accent-gray-800 border-gray-300 ml-4";
        const mergedClassname = twMerge(bespokeCheckboxClassname, this.props.className);
        return (
            <SterlingCheckbox
                {...this.props}
                // Explicit props should be last to properly override if necessary
                className={mergedClassname}
            />

        )
    }
}

/**
 * Internally, this Checkbox component expects to use type="checkbox" and id="name".
 * Any attempts to change those properties 'type' or 'id' via {...rest} will throw an Error.
 */
Checkbox.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelClasses: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    checked: PropTypes.bool
  };