import {updateIntl} from "react-intl-redux";
import locales from "../../i18n/locales";
import actiontypes from "./actiontypes";

export function getProviderWatcher(params) {
  return {type: actiontypes.USER_GET_PROVIDER, payload: params};
}
//TODO: rename me
// I handle all of the back-end challenges
// Previously I handled ALL login flows
// I am referenced in about 27 places in both js and jsx files
export function loginWatcher(authParams) {
  return {type: actiontypes.USER_LOGIN_LEGACY, payload: authParams};
}

//TODO: rename me
// I took over the login with password functionality
export function loginWithPasswordWatcher(authParams) {
  return {type: actiontypes.USER_LOGIN, payload: authParams};
}
// TODO: Rename? This is used to cycle through various UIs
export function loginSetFlowStageWatcher(payload) {
  return {type: actiontypes.USER_LOGIN_FLOW_STAGE, payload: payload};
}

export function saveCognitoUserObject(payload) {
  return {type: actiontypes.SAVE_COGNITO_USER, payload: payload};
}

export function handleAuthResponse(payload) {
  return {type: actiontypes.USER_LOGIN_RESPONSE, payload: payload};
}

export function handleMfaRequired(mfaChallengeData) {
  return {type: actiontypes.MFA_REQUIRED, payload: mfaChallengeData}
}

export function handlePreAuthChallenge(challengeData) {
  return {type: actiontypes.USER_LOGIN_HANDLE_PREAUTH_CHALLENGE, payload: challengeData}
}

export function handleChallengeWatcher(challengeData) {
  return {type: actiontypes.USER_LOGIN_HANDLE_CHALLENGE, payload: challengeData}
}

export function handleGetProviderWatcher(params) {
  return {type: actiontypes.SET_PROVIDER, payload: params};
}

export function handleS1Transport(params) {
  return {type: actiontypes.SET_S1_TRANSPORT, payload: params};
}

export function setLoginSuccess(payload) {
  return {type: actiontypes.USER_LOGIN_SUCCESS, payload}
}

export function setLoginError(loginErrorMessage) {
  return {type: actiontypes.USER_LOGIN_ERROR, payload: loginErrorMessage}
}

export function handleError(payload) {
  return {type: actiontypes.HANDLE_ERROR, payload}
}

export function setLoginErrorMigrationFailed() {
  return {type: actiontypes.USER_LOGIN_ERROR_FAILED_MIGRATION, payload: {}}
}
export function resetPasswordWatcher(authParams) {
  return {type: actiontypes.USER_RESET_PASSWORD, payload: authParams};
}

export function handleResetPasswordWatcher(authParams) {
  return {type: actiontypes.USER_RESET_PASSWORD_RESPONSE, payload: authParams};
}

export function cancelResetPasswordWatcher() {
  return {type: actiontypes.USER_RESET_PASSWORD_CANCEL, payload: true}
}

export function changePasswordWatcher(changePasswordParams) {
  return {type: actiontypes.USER_CHANGE_PASSWORD, payload: changePasswordParams};
}

export function handleChangePasswordWatcher(payload) {
  return {type: actiontypes.USER_CHANGE_PASSWORD_RESPONSE, payload}
}

export function removeProvider() {
  return {type: actiontypes.REMOVE_PROVIDER}
}

export function sendSmsMfaCode(payload) {
  return {type: actiontypes.MFA_SEND_SMS_CODE, payload}
}
export function sendTotpCode(payload) {
  return {type: actiontypes.SEND_TOTP_CODE, payload}
}

export function setTotpSpinning() {
  return {type: actiontypes.TOTP_SPINNING}
}

export function changeLocale(locale) {
  return updateIntl({
    locale: locale,
    messages: locales[locale].localeData
  });
}

export function setPassword(password) {
  return {type: actiontypes.SET_PASSWORD, payload: password}
}

export function setPasswordValidation(password) {
  return {type: actiontypes.SET_PASSWORD_VALIDATION, payload: password}
}

export function handleSetLoginPasswordErrors(errors) {
  return {type: actiontypes.SET_PASSWORD_ERRORS, payload: errors}
}

export function setUsername(username) {
  return {type: actiontypes.SET_USERNAME, payload: username}
}

export function rememberMe(status) {
  return {type: actiontypes.REMEMBER_ME, payload: status}
}

export function saveDevice(status) {
  return {type: actiontypes.SAVE_DEVICE, payload: status};
}

export function mfaSetSmsNumber(mobileNumber) {
  return {type: actiontypes.MFA_SET_SMS_NUMBER, payload: mobileNumber}
}

export function mfaEnableChoice(id, choice) {
  return {type: actiontypes.MFA_ENABLE_CHOICE, payload: {id: id, value: choice}};
}

export function answerMfaChoiceChallenge(payload) {
  return {type: actiontypes.MFA_SELECT_TYPE, payload};
}

export function setMfaTypeSetupComplete(payload) {
  return {type: actiontypes.SET_MFA_TYPE_SETUP_COMPLETE, payload};
}

export function mfaSetupComplete() {
  return {type: actiontypes.MFA_SETUP_COMPLETE};
}

export function setDeviceData(username, deviceKey, deviceGroupKey, devicePassword, expires) {
  return {
    type: actiontypes.SET_DEVICE_DATA,
    payload: {
      username: username,
      deviceKey: deviceKey,
      deviceGroupKey: deviceGroupKey,
      devicePassword: devicePassword,
      expires: expires
    }
  };
}
