import React, { Component } from "react";
import { Grid, Typography, withMobileDialog } from "@material-ui/core";
import { Button } from "../fields/Button";

import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { answerMfaChoiceChallenge } from "../../redux/actions/loginactioncreators";
import PropTypes from "prop-types";
import challengeShape from "../../proptypes/challengeShape";

class MfaChoice extends Component {

  handleEnable = mfaType => {
    this.props.answerMfaChoiceChallenge(mfaType)
  }

  render() {
    const {formatMessage} = this.props.intl;
    const bespokeMfaChoiceButtonClassname = "py-7 mt-3 w-full text-sm";
    return (
      <main>
        <Grid item xs={12}>
          <Grid container className="justify-start">
            <Grid item xs={12}>
              <Typography variant="h6">
                <FormattedMessage id={"mfa.chooseType"} defaultMessage={"Select Your Preferred Method"} />
              </Typography>
              <Typography>
                <FormattedMessage id={"mfa.chooseMfaTypeDescription"} defaultMessage={"Select from your configured Multi-Factor Authentication methods below."} />
              </Typography>
              <br/>
              <Button className={bespokeMfaChoiceButtonClassname}
                      fullWidth={true}
                      onClick={() => this.handleEnable("SMS_MFA")}
                      name="chooseSms"
                      id="chooseSms"
                      variant="outlined"
              >
                {formatMessage({
                  id: "mfa.chooseMfaSmsText",
                  defaultMessage: "Receive Code via SMS {smsDeliveryDestination}",
                }, {smsDeliveryDestination: this.props?.challenge?.challengeParameters?.CODE_DELIVERY_DESTINATION})}
              </Button>

              <Button className={bespokeMfaChoiceButtonClassname}
                      fullWidth={true}
                      onClick={() => this.handleEnable("SOFTWARE_TOKEN_MFA")}
                      name="chooseTotp"
                      id="chooseTotp"
                      variant="outlined"
              >
                <FormattedMessage id={"mfa.chooseMfaTotpText"} defaultMessage={"Use your Time-Based One-Time Password authenticator"} />
              </Button>

            </Grid>
          </Grid>
        </Grid>
      </main>
    )
  }
}

MfaChoice.propTypes = {
  classes: PropTypes.object.isRequired,
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  answerMfaChoiceChallenge: PropTypes.func.isRequired,
  deviceKey: PropTypes.string,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    deviceKey: state.loginForm.deviceKey
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    answerMfaChoiceChallenge
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(withMobileDialog()(MfaChoice)));