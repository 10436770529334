import React, {Component} from "react";
import LocalizationSelect from "./LocalizationSelect";
import {Grid} from "@material-ui/core";
import ContactUsLink from "./ContactUsLink";
import PropTypes from "prop-types";
class Footer extends Component {
  render() {
    return (
      <Grid container>
        <Grid item xs={6}>
          <LocalizationSelect/>
        </Grid>

        <Grid item xs={6} className="flex items-center justify-end">
          <ContactUsLink/>
        </Grid>
      </Grid>
    )
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
}
export default Footer;