import {put, select, takeLatest} from "redux-saga/effects"
import { loginSetFlowStageWatcher } from "../actions/loginactioncreators"
import {
  handleError,
  handleChallengeWatcher} from "../actions/loginactioncreators";
import getClientId from "../helpers/getClientId";
import getRefreshToken from "../helpers/getRefreshToken";
import {navigateToAuthorize} from "../helpers/navigators";
import axios from "axios";
import actiontypes from "../actions/actiontypes";
import getAuthToken from "../helpers/getAuthToken";
import getloginClientId from "../helpers/getLoginClientId";

export function* createSession() {

  try {
    const clientId = yield select(getClientId);
    const refreshToken = yield select(getRefreshToken);
    const authToken = yield select(getAuthToken);
    const loginClientId = yield select(getloginClientId);

    let deviceKey;
    const keyPrefix = `CognitoIdentityServiceProvider.${loginClientId}`;
    const lastUserKey = `${keyPrefix}.LastAuthUser`;
    if (localStorage.getItem(lastUserKey)) {
      const deviceKeyKey = `${keyPrefix}.${localStorage.getItem(lastUserKey)}.deviceKey`;
      deviceKey = localStorage.getItem(deviceKeyKey);
    }

    const sessionData = {clientId, refreshToken}
    if (deviceKey) {
      sessionData.deviceKey = deviceKey;
    }
    const {data} = yield axios.post(
      "/login/acceptTokens",
      { sessionData },
      { headers: { "Authorization": authToken } }
    );
    
    if (data && data.challenge) {
      yield put(handleChallengeWatcher(data))
    } else if (data.flowStage
      && data.flowStage !== "AUTHENTICATED")
    {
      yield put(loginSetFlowStageWatcher(data));
    } else {
      navigateToAuthorize();
    }
  } catch (error) {
    yield put(handleError(error));
  }
}


export function* watchLoginSuccessAsync() {
  yield takeLatest([actiontypes.USER_LOGIN_SUCCESS, actiontypes.MFA_SETUP_COMPLETE], createSession);
}