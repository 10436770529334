export default {
  USER_GET_PROVIDER: 'USER_GET_PROVIDER',
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGIN_LEGACY: 'USER_LOGIN_LEGACY',
  USER_LOGIN_RESPONSE: 'USER_LOGIN_RESPONSE',
  SAVE_COGNITO_USER: 'SAVE_COGNITO_USER',
  USER_LOGIN_SUCCESS: 'USER_LOGIN_SUCCESS',
  USER_LOGIN_FLOW_STAGE: 'USER_LOGIN_FLOW_STAGE',
  USER_LOGIN_HANDLE_CHALLENGE: 'USER_LOGIN_HANDLE_CHALLENGE',
  USER_LOGIN_HANDLE_PREAUTH_CHALLENGE: 'USER_LOGIN_HANDLE_PREAUTH_CHALLENGE',
  USER_PROVIDER: 'USER_PROVIDER',
  USER_LOGIN_ERROR: 'USER_LOGIN_ERROR',
  USER_LOGIN_ERROR_FAILED_MIGRATION: 'USER_LOGIN_ERROR_FAILED_MIGRATION',
  USER_RESET_PASSWORD: 'USER_RESET_PASSWORD',
  USER_RESET_PASSWORD_RESPONSE: 'USER_RESET_PASSWORD_RESPONSE',
  USER_RESET_PASSWORD_CANCEL: 'USER_RESET_PASSWORD_CANCEL',
  USER_CHANGE_PASSWORD: 'USER_CHANGE_PASSWORD',
  USER_CHANGE_PASSWORD_RESPONSE: 'USER_CHANGE_PASSWORD_RESPONSE',
  USER_CHANGE_PASSWORD_ERROR: 'USER_CHANGE_PASSWORD_ERROR',
  HANDLE_ERROR: 'HANDLE_ERROR',
  REMOVE_PROVIDER: 'REMOVE_PROVIDER',
  SET_PROVIDER: 'SET_PROVIDER',
  SET_S1_TRANSPORT: 'SET_S1_TRANSPORT',
  TOTP_SPINNING: 'TOTP_SPINNING',
  SEND_TOTP_CODE: 'SEND_TOTP_CODE',
  SET_USERNAME: 'SET_USERNAME',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_PASSWORD_VALIDATION: 'SET_PASSWORD_VALIDATION',
  SET_PASSWORD_ERRORS: 'SET_PASSWORD_ERRORS',
  REMEMBER_ME: 'REMEMBER_ME',
  SET_DEVICE_DATA: 'SET_DEVICE_DATA',
  SAVE_DEVICE: 'SAVE_DEVICE',
  MFA_SETUP_COMPLETE: 'MFA_SETUP_COMPLETE',
  SET_MFA_TYPE_SETUP_COMPLETE: 'SET_MFA_TYPE_SETUP_COMPLETE',
  MFA_REQUIRED: 'MFA_REQUIRED',
  MFA_SET_SMS_NUMBER: 'MFA_SET_SMS_NUMBER',
  MFA_SEND_SMS_CODE: 'MFA_SEND_SMS_CODE',
  MFA_SELECT_TYPE: 'MFA_SELECT_TYPE',
  MFA_ENABLE_CHOICE: 'MFA_ENABLE_CHOICE',
}