import {put, select, takeLatest} from "redux-saga/effects"
import { handleAuthResponse } from "../actions/loginactioncreators"
import { loginWatcher, handleError } from "../actions/loginactioncreators";
import getPassword from "../helpers/getPassword";
import getTotpSession from "../helpers/getTotpSession";
import getIsPreAuth from "../helpers/getIsPreAuth";
import getCognitoUser from "../helpers/getCognitoUser";
// import getSaveDevice from "../helpers/getSaveDevice";// TODO COR-1814
import actiontypes from "../actions/actiontypes";
import getCognitoIdentityCallbacks from "../../lib/cognitoIdentityCallbacks";
import getChallenge from "../helpers/getChallenge";
// import getFlowStage from "../helpers/getFlowStage"; // See below

export function* sendTotpCode(action) {
  try {
    const isPreAuth = yield select(getIsPreAuth);
    // TODO COR-1814
    // const saveDevice = yield select(getSaveDevice);
    if (isPreAuth) {
      const cognitoUser = yield select(getCognitoUser);
      const cognitoResponse = yield new Promise((resolve,reject) => {
        return cognitoUser.sendMFACode(
          action.payload,
          getCognitoIdentityCallbacks(resolve, reject),
          "SOFTWARE_TOKEN_MFA"
        )
      });
      yield put(handleAuthResponse(cognitoResponse));
    } else {
      const challenge = yield select(getChallenge);
      const totpSession = yield select(getTotpSession);
      const password = yield select(getPassword);
      //Todo name
      // It may be possible to replace all of the following by using the Amplify Cognito lib
      const totpPayload = {
        flowStage: "CHALLENGE_MFA_SETUP",
        challengeResponse: {
          session: challenge.session,
          challengeName: challenge.challengeName,
          step: "MFA_SETUP_TOTP",
          challengeParameters: {
            USERNAME: challenge.challengeParameters.USER_ID_FOR_SRP,
            MFA_CONFIG: {
              TOTP_CODE: action.payload,
              TOTP_SESSION: totpSession
            },
            PASSWORD: password
          }
        }
      };
      // This shouldn't be needed anymore, TOTP should only be pre-auth now
      //
      // const flowStageFromState = yield select(getFlowStage);
      // if (flowStageFromState === "SOFTWARE_TOKEN_MFA") {
      //   totpPayload.flowStage = "CHALLENGE";
      //   totpPayload.challengeResponse.challengeParameters.SOFTWARE_TOKEN_MFA_CODE = action.payload;
      //   delete totpPayload.challengeResponse.step;
      //   delete totpPayload.challengeResponse.challengeParameters.MFA_CONFIG;
      // }
      // eslint-disable-next-line no-console
      yield put(loginWatcher(totpPayload));
    }
    // if (saveDevice) {
    //   // TODO COR-1814 implement device key first
    //   // REQUIRES VALID DEVICE KEY TO HAVE BEEN SET ELSEWHERE
    //   yield cognitoUser.setDeviceStatusRemembered({
    //     onSuccess: function(result) { },
    //     onFailure: function(rememberDeviceFailure) {
    //       // TODO decide what to do here
    //     },
    //   });
    // }
  } catch (error) {
    yield put(handleError(error));
  }
}


export function* watchTotpCodeAsync() {
  yield takeLatest(actiontypes.SEND_TOTP_CODE, sendTotpCode);
}
