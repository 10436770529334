import React, { Component } from "react";
import { CircularProgress, Grid, Typography, Paper } from "@material-ui/core";
import { Button } from "../fields/Button";
import { TextField } from "../fields/TextField";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendTotpCode, setTotpSpinning } from "../../redux/actions/loginactioncreators";
import * as authenticator from "authenticator";
import * as QRCode from "qrcode.react";
import challengeShape from "../../proptypes/challengeShape";
import PropTypes from "prop-types";
import userInfoShape from "../../proptypes/userInfoShape";
import ExpansionPanel, { ExpansionPanelDetails, ExpansionPanelSummary } from "@sterling-react/expansion-panel";
import { Check } from "@material-ui/icons";


class MfaTotp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      totpCode: "",
      totpCodeError: false,
      totpCodeValid: false,
    }
  }

  validate(totpCode) {
    if (totpCode.length === 6) {
      try {
        return authenticator.verifyToken(this.props.secretCode, totpCode);
      } catch (error) {
        console.error("Error while trying to validate token", error)
      }
    }
    return false;
  }

  handleTotpChange = event => {
    let valid = false;
    let error = false;
    if (event.target.value.length === 6) {
      valid = this.validate(event.target.value);
      error = !valid;
    }
    this.setState({
      totpCode: event.target.value,
      totpCodeValid: valid,
      totpCodeError: error
    });

  }
  handleClick = () => {
    const valid = this.validate(this.state.totpCode);
    if (valid) {
      this.props.sendTotpCode(this.state.totpCode)
    } else {
      this.setState({
        totpCodeInvalid: true,
        totpCodeError: true
      })
    }
  }
  formatTotpSecretCode = () => {
    return this.props.secretCode.split("").reduce((a, e, i) => a + e + (i % 4 === 3 ? " " : ""), "")
  }
  render() {
    let qrCode = false
    if (this.props.secretCode) {
      qrCode = authenticator.generateTotpUri(this.props.secretCode, this.props.userInfo.UserAttributes.email, "Sterling Talent Solutions", "SHA1", 6, 30);
    }
    const bespokeTotpMfaExpansionPanelClassname = "mx-auto w-[600px] max-w-full rounded-md py-1 border";
    const bespokeTotpMfaExpansionPanelDetailsClassname = "px-2 my-4 bg-#f5f5f5 duration-300";
    const bespokeTotpMfaExpansionPanelSummaryClassname = "bg-inherit px-5 py-3 text-lg";
    const paperPaddingClassname = "p-1.5";
    const totpCodeNextButtonMarginClassname = "mt-4";
    return (
      <div id="totp">
      <ExpansionPanel name="totp" id="totp" className={bespokeTotpMfaExpansionPanelClassname}>
        <ExpansionPanelDetails
          className={bespokeTotpMfaExpansionPanelDetailsClassname}
        >
          <ExpansionPanelSummary
            className={bespokeTotpMfaExpansionPanelSummaryClassname}
          >
            <FormattedMessage id={"mfaSetup.totpSetup"} defaultMessage={"Time-Based One-Time Password"} />
            {this.props.complete && <Check />}
          </ExpansionPanelSummary>

            {this.props.spinning &&
              <CircularProgress />
            }

            {!this.props.spinning && !this.props.complete &&
              <React.Fragment>
                <Grid container className="mt-4">
                  <Grid item xs={12}>
                    <Typography>
                      <FormattedMessage id={"mfaSetup.totpSetupBlurb"} defaultMessage={"Use Time-Based One-Time Passwords (TOTP) via a software application such as Google Authenticator"} />
                    </Typography>
                  </Grid>
                </Grid>
                {qrCode &&
                  <React.Fragment>
                    <Grid container className="justify-center mt-4">
                      <Grid item>
                        <QRCode value={qrCode} />
                        <input type="hidden" id="totpUri" value={qrCode} />
                      </Grid>
                    </Grid>
                    <Grid container className="mt-4">
                      <Grid item xs={12}>
                        <Typography>
                          <FormattedMessage id={"mfaSetup.totpSetupScanBlurb"} defaultMessage={"Scan the barcode above into your App, then enter the 6 digit value generated before the time runs out."} />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="mt-4">
                      <Grid item xs={12}>
                        <Typography>
                          <FormattedMessage id={"mfaSetup.totpSetupSecretCodeLabel"} defaultMessage={"Can't scan the barcode? Enter the key provided below manually into your App to generate the 6 digit value."} />
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="mt-4">
                      <Grid item xs={12}>
                        <Paper square={true} id="totpCode" elevation={0} className={paperPaddingClassname}>
                          <Typography variant="caption">
                            {this.formatTotpSecretCode()}
                          </Typography>
                        </Paper>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                }


                <Grid container className="mt-4">
                  <Grid item xs={8}>
                    <TextField
                      fullWidth
                      error={this.state.totpCodeError}
                      onChange={this.handleTotpChange}
                      label={<FormattedMessage id={"mfaSetup.totpSetupVerificationCode"} defaultMessage={"Verification Code"} />}
                      id="verificationCode"
                      name="verificationCode"
                      autoFocus
                      errorText={<FormattedMessage id={"codeMismatchException"} defaultMessage={"Invalid authorization code."} />}
                    />
                  </Grid>

                  <Grid item xs={1}></Grid>
                  <Grid item xs={3}>
                    <Button
                      fullWidth={true}
                      className={totpCodeNextButtonMarginClassname}
                      disabled={!this.state.totpCodeValid}
                      id="totpCodeNext"
                      name="totpCodeNext"
                      onClick={this.handleClick}
                    >
                      <FormattedMessage id={"mfaSetup.totpSaveButton"} defaultMessage={"Verify"} />
                    </Button>
                  </Grid>
                </Grid>
              </React.Fragment>
            }

        </ExpansionPanelDetails>
      </ExpansionPanel>
      </div>
    )
  }
}

MfaTotp.propTypes = {
  classes: PropTypes.object.isRequired,
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  spinning: PropTypes.bool,
  secretCode: PropTypes.string,
  deviceKey: PropTypes.string,
  sendTotpCode: PropTypes.func.isRequired,
  complete: PropTypes.bool,
  password: PropTypes.string,
  totpSession: PropTypes.string,
  userInfo: userInfoShape
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    userInfo: state.loginForm.userInfo,
    username: state.loginForm.username,
    password: state.loginForm.password,
    deviceKey: state.loginForm.deviceKey,
    spinning: state.loginForm.totpSpinning,
    secretCode: state.loginForm.secretCode || null,
    totpSession: state.loginForm.totpSession || null
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendTotpCode,
    setTotpSpinning
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MfaTotp));
