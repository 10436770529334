import SterlingButton from "@sterling-react/button";
import PropTypes from "prop-types";
import { Component } from "react";
import { twMerge } from "tailwind-merge";
export class Button extends Component {
    constructor(props) {
        super(props);
    }

    
    render() {
        const bespokeButtonClassname = "btn mr-2 px-2.5 py-1 font-sans tracking-wide";
        const buttonVariantClassname = this.props.variant !== "text" && "hover:bg-primary-700 hover:shadow-md";
        const mergedClassname = twMerge(
            bespokeButtonClassname,
            buttonVariantClassname,
            this.props.className
        );
        return (
            <SterlingButton
                {...this.props}
                // Explicit props should be last to properly override if necessary
                className={mergedClassname}
            />

        )
    }
}

Button.propTypes = {
    ariaLabel: PropTypes.string.isRequired,
    children: PropTypes.node,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.oneOf(["button", "reset", "submit"]),
    variant: PropTypes.oneOf(["default", "text", "outlined", "hyperlink"]),
  };