import React, { Component } from "react";
import { connect} from "react-redux";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { resetPasswordWatcher } from "../../redux/actions/loginactioncreators";
import PropTypes from "prop-types";
import { Button } from "../fields/Button";

export class ResetPasswordLink extends Component {
  showPasswordResetDialog = () => {
    this.props.resetPasswordWatcher(
      {
        step: "RESET_PASSWORD",
        username: this.props.username.trim().toLowerCase()
      }
    )
  }

  render() {
    const bespokeForgotPasswordLinkClassname = "whitespace-nowrap font-bold text-sm";
    return (
      <Button
        id="forgotYourPassword"
        name="forgotYourPassword"
        onClick={this.showPasswordResetDialog}
        variant="text"
        className={bespokeForgotPasswordLinkClassname}
      >
        <FormattedMessage id={"login.forgotYourPassword"} defaultMessage={"Forgot Password?"} />
      </Button>
    )
  }
}

ResetPasswordLink.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.objectOf(IntlProvider),
  username: PropTypes.string,
  locale: PropTypes.string,
  resetPasswordWatcher: PropTypes.func.isRequired,
}

const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    locale: state.intl.locale,
    passwordErrors: state.loginForm.passwordErrors
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordLink));