export default function getCognitoIdentityCallbacks(resolve, reject) {
    return {
        onFailure: reject,
        onSuccess:  (responseData)=>{
            resolve({challengeName: "", responseData: responseData});
        },
        mfaRequired: (challengeName, challengeParameters)=>{
            resolve({challengeName: "SMS_MFA", responseData: {challengeName, challengeParameters}});
        },
        totpRequired: (secretCode)=>{
            resolve({challengeName: "SOFTWARE_TOKEN_MFA", responseData: secretCode});
        },
        mfaSetup: (responseData)=>{
            resolve({challengeName: "MFA_SETUP", responseData: responseData});
        },
        selectMFAType: (challengeName, challengeParameters)=>{
            resolve({challengeName: "SELECT_MFA_TYPE", responseData: {challengeName, challengeParameters}});
        },
        newPasswordRequired: (userAttributes, requiredAttributes)=>{
            //delete userAttributes.email; // TODO: validate this in legacy env
            delete userAttributes.email_verified;
            delete userAttributes.phone_number_verified;

            resolve({challengeName: "NEW_PASSWORD_REQUIRED", responseData: {userAttributes, requiredAttributes}});
        },
        // This will be needed if require MFA is turned on for the userpool
        // Or if there is another reason Cognito would force MFA_SETUP on a new user
        associateSecretCode: (responseData)=>{
            resolve({challengeName: "NOT_IMPLEMENTED", responseData: responseData});
        },
        customChallenge: (responseData)=>{
            resolve({challengeName: "NOT_IMPLEMENTED", responseData: responseData});
        },
      }
}