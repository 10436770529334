// todo: consolidate this form and the one in react-sso-landing

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { loginWatcher } from "../../redux/actions/loginactioncreators";
import { bindActionCreators } from "redux";
import { Grid, Typography } from "@material-ui/core";
import { TextField } from "../fields/TextField";
import { Button } from "../fields/Button";
import * as EmailValidator from "email-validator";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import challengeShape from "../../proptypes/challengeShape";
import userInfoShape from "../../proptypes/userInfoShape";
import flowStages from "../../flowStages";

export class ConfirmEmailAddress extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: props?.userInfo?.UserAttributes?.email,
    }
  }
  handleChangeCode = event => {
    const code = event.target.value;

    this.setState({code: code});
  }
  handleSubmit = () => {
    this.props.loginWatcher({
      flowStage: flowStages.VERIFY_EMAIL,
      username: this.props.username,
      password: this.props.password,
      challengeResponse: {
        session: this.props.challenge.session,
        challengeName: this.props.challenge.challengeName,
        challengeParameters: {
          USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
          CODE: this.state.code,
        }
      }
    });
  }
  validateEmail(email) {
    if(!email) {
      return false;
    }
    return EmailValidator.validate(email);
  }
  render() {
    const {formatMessage} = this.props.intl;
    return (
      <Fragment>
        <Grid container className="justify-center">
          <Grid item xs={12}>
            <Typography variant="body1">{formatMessage({
              id: "login.confirmEmailBlurb",
              defaultMessage: "An email was sent to {destination} with a verification code.  Please type it into the input below to confirm your email address.",     
            }, {destination: this.props?.challenge?.challengeParameters?.["DESTINATION"]})}</Typography>
            <br/>
            <br/>
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="confirmEmailAddress"
              name="confirmEmailAddress"
              autoFocus={true}
              label={<FormattedMessage id={"login.emailAddressConfirmationCode"} defaultMessage={"Confirmation Code"} />}
              onChange={this.handleChangeCode}
            />
          </Grid>
        </Grid>
        <br/>
        <Grid container className="justify-end">
          <Grid item>
            <Button
              id="submit"
              name="submit"
              disabled={!this.state.code || (this.state.code.length < 6 && this.state.code.length > 6)}
              onClick={this.handleSubmit}
            >
              <FormattedMessage id={"login.confirmEmailSubmit"} defaultMessage={"Confirm Email"} />
            </Button>
          </Grid>
          <br/>
        </Grid>
      </Fragment>
    )
  }
}

ConfirmEmailAddress.propTypes = {
  classes: PropTypes.object.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  loginWatcher: PropTypes.func.isRequired,
  userInfo: userInfoShape,
  intl: PropTypes.objectOf(IntlProvider),
  challenge: challengeShape,
}
const mapStateToProps = state => {
  return {
    userInfo: state.loginForm.userInfo,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null,
    username: state.loginForm.username,
    password: state.loginForm.password,
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ConfirmEmailAddress));