import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ErrorIcon from "@material-ui/icons/Error";
import Banner, { ActionBar } from "@sterling-react/banner";
import { Button } from "./fields/Button";

class AlertBanner extends React.Component {
    render () {
        const { alert } = this.props;

        const linkText = alert.linkText ?? "";
        const bannerMessageClassname = "px-2 text-base";
        const bannerClassname = "bg-error-800 border-error-800";
        const bannerIconClassname = "bg-error-800 mr-3.5";
        const bannerButtonClassname = "my-2 color-black";
        return (
        <div id="alertBanner">
            <Banner
                actionLabel=''
                dismissLabel=''
                message={<p id="bannerMessage" className={bannerMessageClassname}>{alert.message}</p>}
                notDismissable
                className={bannerClassname}
                icon={<ErrorIcon className={bannerIconClassname}/>}
            >
                {linkText && <ActionBar>
                    <Button
                        id="alertBannerLink"
                        variant="text"
                        ariaLabel='A link to information about this alert'
                        className={bannerButtonClassname}
                    >
                        {linkText}
                    </Button>
                </ActionBar>}
            </Banner>
        </div>
        );
    }
}

AlertBanner.propTypes = {
    alert:  PropTypes.object.isRequired
}

const mapStateToProps = state => ({
        alert: state.loginForm.alert,
    });

export default connect(mapStateToProps)(AlertBanner);