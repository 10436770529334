import {FormControl, Input, MenuItem, Select, Typography} from "@material-ui/core";
import locales from '../i18n/locales';
import React, {Component} from 'react';
import {changeLocale} from "../redux/actions/loginactioncreators";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';

class LocalizationSelect extends Component {
  handleChange = event => {
    this.props.changeLocale(event.target.value);
  }

  render() {
    return (
      <FormControl>
        <Select
          value={this.props.locale}
          onChange={this.handleChange}
          input={<Input disableUnderline={true}/>}
          inputProps={{
            name: 'locale',
            id: 'locale',
          }}
        >
          {this.props.allowedLocales.map((key, index) => {
            return (
              <MenuItem value={key} key={index}><Typography
                variant="body1">{locales[key].localeName}</Typography></MenuItem>
            )
          })
          }
        </Select>
      </FormControl>
    )
  }
}

LocalizationSelect.propTypes = {
  changeLocale: PropTypes.func.isRequired,
  locale: PropTypes.string,
  allowedLocales: PropTypes.arrayOf(PropTypes.string),
}
const mapStateToProps = state => {
  return {
    allowedLocales: state.intl.allowedLocales,
    locale: state.intl.locale || state.intl.defaultLocale
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changeLocale
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(LocalizationSelect);