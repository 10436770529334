import actiontypes from "../actions/actiontypes";
import {handlePreAuthChallenge, setLoginError, setLoginSuccess, handleError} from "../actions/loginactioncreators";
import {put, takeLatest} from "redux-saga/effects"

export function* authResponseHandler(action) {
  try {
    const response = action.payload;
    if (response.challengeName) {
      yield put(handlePreAuthChallenge(response));
    } else if (response?.responseData?.idToken?.jwtToken
    && response?.responseData?.refreshToken?.token)
    {
      yield put(setLoginSuccess(response.responseData))
    } else if (response?.idToken?.jwtToken && response?.refreshToken?.token)
    {
      yield put(setLoginSuccess(response))
    } else {
      yield put(setLoginError(["Login failed"]));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* watchAuthResponse() {
  yield takeLatest(actiontypes.USER_LOGIN_RESPONSE, authResponseHandler);
}