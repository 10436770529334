import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import HyperLink from "./HyperLink";
import PropTypes from "prop-types";
import { Button } from "./fields/Button";

export class ContactUsLink extends Component {

    /**
     * @return {string}
     */
    getContactUsUrl() {
        const locale = typeof (this.props || {}).locale === "undefined" ? "" : this.props.locale;
        switch(locale.toLowerCase()) {
            case "fr-ca":
                return "https://www.sterlingtalentsolutions.ca/fr/platform-contact/";
            case "en-us":
            default:
                return "https://www.sterlingcheck.com/platform-contact/";
        }
    }
    render() {
        const bespokeContactUsButtonClassname = "font-bold whitespace-nowrap";
        return (
            <HyperLink 
                target="_blank" 
                to={this.getContactUsUrl()} 
            >
                <Button
                    fullWidth
                    component={HyperLink}
                    id="contactUs"
                    name="contactUs"
                    variant="text"
                    className={bespokeContactUsButtonClassname}
                >
                    <FormattedMessage id={"login.contactUsText"} defaultMessage={"Contact Us"} />
                </Button>
            </HyperLink>
        )
    }
}

ContactUsLink.propTypes = {
    locale: PropTypes.string,
    
}

ContactUsLink.propTypes = {
  classes: PropTypes.object.isRequired,
  intl: PropTypes.objectOf(IntlProvider),
  locale: PropTypes.string
}
const mapStateToProps = state => {
  return {
    contactUsUrl: state.loginForm.contactUsUrl,
    locale: state.intl.locale
  }
};
export default connect(mapStateToProps)(injectIntl(ContactUsLink));