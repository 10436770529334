
// todo: consolidate this form and the one in react-sso-landing
import React, { Fragment, Component } from "react";
import { Grid, Typography, withMobileDialog } from "@material-ui/core";
import { Button } from "../fields/Button";
import { TextField } from "../fields/TextField";

import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import PasswordField from "../fields/PasswordField";
import CognitoPasswordValidator from "./CognitoPasswordValidator";
import PropTypes from "prop-types";

export class ChangePasswordForm extends Component {

  constructor(props) {
    super(props);
    this.passwordValidator = props.passwordValidator;
    this.state = {
      newPassword: {
        value: "",
        valid: false
      },
      oldPassword: {
        value: "",
        valid: false
      },
      verifyPassword: {
        value: "",
        valid: false
      },
      submitDisabled: true,
    };
  }

  handleOldPasswordUpdate = event => {
    this.setState({
      oldPassword: {
        value: event.target.value
      }
    })
  }

  handleUpdate = event => {
    let state = this.state;
    let passwordsValid;
    state[event.target.name].value = event.target.password;
    state[event.target.name].valid = event.target.valid;

    let passwordsMatch = true;
    if (state.newPassword.value !== state.verifyPassword.value) {
      passwordsMatch = false;
    }

    let passwordNotReused = true;
    if (state.oldPassword.value === state.newPassword.value || state.oldPassword.value === state.verifyPassword.value ) {
      passwordNotReused = false;
    }
    passwordsValid = state.newPassword.valid && state.oldPassword.valid && state.verifyPassword.valid;

    state.submitDisabled = !(passwordsMatch && passwordNotReused && passwordsValid);
    this.setState(state);
  }

  handleOnSubmit = () => {
    this.props.onSubmit(
      {
        oldPassword: this.state.oldPassword.value,
        newPassword: this.state.newPassword.value
      }
    )
  }

  enableSubmit = () => {
    let state = this.state;
    if (state.newPassword === state.verifyPassword && state.oldPassword.length > 0 && state.oldPassword.value !== state.newPassword.value) {
      state.submitDisabled = false;
      this.setState(state);
    }
  }

  render() {
    const cognitoPasswordValidator = new CognitoPasswordValidator(8, 99, true, true, true, true)
    return (
      <Fragment>
        <Grid container className='justify-center'>
          <Grid item xs={12}>
            <Typography variant="h6">{
              <FormattedMessage id="changePassword.changePasswordBlurb" defaultMessage="Your password must be changed." />
            }</Typography>
            <Typography>
              <FormattedMessage id="changePassword.changePasswordInstructions" defaultMessage="Enter your current password then choose a new password." />
            </Typography>
          <br />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="oldPassword"
              name="oldPassword"
              autoFocus={true}
              type="password"
              label={<FormattedMessage id="changePassword.oldPassword" defaultMessage="Current password" />}
              onChange={(evt) => this.handleUpdate({target: {name: "oldPassword", password: evt.target.value, valid: evt.target.value.length > 0}})} />
            <PasswordField
              id="newPassword"
              name="newPassword"
              match={this.state.verifyPassword.value}
              oldPwd={this.state.oldPassword.value}
              passwordValidator={cognitoPasswordValidator}
              label={<FormattedMessage id="changePassword.newPassword" defaultMessage="New password" />}
              onChange={this.handleUpdate} />
            <PasswordField
              id="verifyPassword"
              name="verifyPassword"
              match={this.state.newPassword.value}
              oldPwd={this.state.oldPassword.value}
              passwordValidator={cognitoPasswordValidator}
              label={<FormattedMessage id="changePassword.verifyPassword" defaultMessage="Confirm new password" />}
              onChange={this.handleUpdate} />
          </Grid>
        </Grid>
        <br />
        <Grid container className="justify-end">
          <Grid item>
            <Button
              id="submit"
              name="submit"
              onClick={this.handleOnSubmit}
              disabled={this.state.submitDisabled}
              variant="contained"
              color="primary"
            >
              <FormattedMessage id="changePassword.submit" defaultMessage="Change password" />
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}
ChangePasswordForm.propTypes = {
  intl: PropTypes.objectOf(IntlProvider),
  passwordValidator: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  classes: PropTypes.object.isRequired,

}
export default injectIntl(withMobileDialog()(ChangePasswordForm))