// todo: consolidate this form and the one in react-sso-landing

import React, { Component, Fragment } from "react";
import { connect} from "react-redux";
import { loginSetFlowStageWatcher, loginWatcher } from "../../redux/actions/loginactioncreators";
import { bindActionCreators } from "redux";
import { Grid, Typography } from "@material-ui/core";
import * as EmailValidator from "email-validator";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import challengeShape from "../../proptypes/challengeShape";
import PropTypes from "prop-types";
import flowStages from "../../flowStages";
import { Button } from "../fields/Button";

export class SkipChangeEmailAddress extends Component {
  constructor(props) {
    super(props);
  }

  handleCancel = () => {
    this.props.loginSetFlowStageWatcher({
      flowStage: flowStages.EMAIL_NOT_VERIFIED,
    })
  }
  handleSubmit = () => {
    this.props.loginWatcher({
      flowStage: flowStages.CHANGE_EMAIL_ADDRESS,
      username: this.props.username,
      password: this.props.password,
      challengeResponse: {
        session: this.props.challenge.session,
        challengeName: this.props.challenge.challengeName,
        challengeParameters: {
          USERNAME: this.props.challenge.challengeParameters.USER_ID_FOR_SRP,
          SKIP: true
        }
      }
    });
  }

  validateEmail(email) {
    if (!email) {
      return false;
    }
    return EmailValidator.validate(email);
  }

  render() {
    return (
      <Fragment>
        <Grid container className="justify-center">
          <Grid item xs={12}>
            <Typography variant="h6">
              <FormattedMessage
                id="login.skipEmailChangeTitle"
                defaultMessage="Are you sure?"
              />
            </Typography>
            <Typography variant="body1">
              <FormattedMessage
                id="login.skipEmailChangeBlurb"
                defaultMessage="If you skip this step, and the username you provided is associated with an e-mail address already on file, you will need to contact customer                   support to change your password."
              />
            </Typography>
            <br/>
          </Grid>
        </Grid>
        <br/>
        <br/>
        <Grid container className="justify-end">
          <Grid item>
            <Button
              id="cancel"
              name="cancel"
              onClick={this.handleCancel}
              variant="outlined"
              color="primary"
            >
              <FormattedMessage id={"login.cancelSkipEmailSubmit"} defaultMessage={"Cancel"} />
            </Button>
          </Grid>
          <Grid
            item
          >
            <Button
              id="submit"
              name="submit"
              onClick={this.handleSubmit}
              variant="contained"
              color="primary"
            >
              <FormattedMessage id={"login.skipEmailSubmit"} defaultMessage={"Skip This Step"} />
            </Button>
          </Grid>
          <br/>
        </Grid>
      </Fragment>
    )
  }
}

SkipChangeEmailAddress.propTypes = {
  loginWatcher: PropTypes.func.isRequired,
  loginSetFlowStageWatcher: PropTypes.func.isRequired,
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  username: PropTypes.string,
  password: PropTypes.string,
  classes: PropTypes.object.isRequired,
}
const mapStateToProps = state => {
  return {
    userInfo: state.loginForm.userInfo,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null,
    username: state.loginForm.username,
    password: state.loginForm.password
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    loginWatcher,

    loginSetFlowStageWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SkipChangeEmailAddress));