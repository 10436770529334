import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cancelResetPasswordWatcher, resetPasswordWatcher } from "../../redux/actions/loginactioncreators";
import PasswordField from "../fields/PasswordField";
import CognitoPasswordValidator from "../changePasswordForm/CognitoPasswordValidator";
import PropTypes from "prop-types";
import challengeShape from "../../proptypes/challengeShape";
import { TextField } from "../fields/TextField";
import { Button } from "../fields/Button";

const validator = new CognitoPasswordValidator(8, 99, true, true, true, true);

export class ForgotPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginErrorMessages: [],
      validationErrors: {},
      username: null,
      password: null,

      newPassword: {
        value: "",
        valid: false
      },
      code: {
        value: "",
        valid: false
      },
      verifyPassword: {
        value: "",
        valid: false
      },
      submitEnabled: false,
    }
  }

  handleCodeChange = event => {
    this.setState({
      code: {
        value: event.target.value,
        valid: event.target.value ? event.target.value.length > 0 : false
      },
      submitEnabled: this.state.newPassword.valid && this.state.verifyPassword.valid && event.target.value ? event.target.value.length > 0 : false
    });
  }

  handlePasswordChange = event => {
    const pwdInputId = "newPassword";
    const currentPWDValue = document.getElementById(pwdInputId).value;
    const matchPwdInputId = "newPasswordConfirm";
    const matchValue = document.getElementById(matchPwdInputId).value;
    const errors = [];

    errors.push(...validator.validatePasswordFormat(currentPWDValue));
    errors.push(...validator.validatePasswordRules(currentPWDValue, matchValue));
    const isValid = errors.length === 0 ? true : false;

    this.setState({
      newPassword: {
        value: event.target.password,
        valid: isValid
      },
      submitEnabled: this.state.code.valid && this.state.verifyPassword.valid && isValid
    });
  }

  handleVerifyPasswordChange = event => {
    const pwdInputId = "newPasswordConfirm";
    const currentPWDValue = document.getElementById(pwdInputId).value;
    const matchPwdInputId = "newPassword";
    const matchValue = document.getElementById(matchPwdInputId).value;
    const errors = [];

    errors.push(...validator.validatePasswordFormat(currentPWDValue));
    errors.push(...validator.validatePasswordRules(currentPWDValue, matchValue));
    const isValid = errors.length === 0 ? true : false;

    this.setState({
      verifyPassword: {
        value: event.target.password,
        valid: isValid
      },
      submitEnabled: this.state.code.valid && this.state.newPassword.valid && isValid
    });
  }

  handleCloseResetPassword = () => {
    this.props.cancelResetPasswordWatcher()
  }

  handleOnSubmit = () => {
    this.props.resetPasswordWatcher(
      {
        step: "CONFIRM_RESET_PASSWORD",
        username: this.props.username.trim().toLowerCase(),
        code: this.state.code.value.trim(),
        password: this.state.newPassword.value.trim()
      }
    )
  }

  render() {
    const { formatMessage } = this.props.intl;

    const deliveryMedium = this.props.challenge.challengeParameters?.CodeDeliveryDetails?.DeliveryMedium;// !nocommit
    let deliveryMediumText = <FormattedMessage id={"resetPassword.deliveryMediumEmail"} defaultMessage={"email"} />;
    if (deliveryMedium === "SMS") {
      deliveryMediumText = <FormattedMessage id={"resetPassword.deliveryMediumSms"} defaultMessage={"text message"} />;
    }

    return (
      <main>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">{
              <FormattedMessage id={"login.forgotPasswordTitle"} defaultMessage={"Forgot your password?"} />
            }</Typography>
            <Typography>
              {formatMessage({
                id: "login.forgotPasswordBlurb",
                defaultMessage: "An email with a verification code was sent to the email address associated with your login. If you do not receive a verification code, please contact support at the number provided in the 'Contact Us' link below.",
              }, {
                deliveryMedium: deliveryMediumText
              })}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="code"
              name="code"
              label={<FormattedMessage id={"resetPassword.codeEntry"} defaultMessage={"Verification Code"} />}
              onChange={this.handleCodeChange}
              error={this.state.validationErrors.code}
              autoFocus={true}
            />
            <PasswordField
              id="newPassword"
              name="newPassword"
              label={<FormattedMessage id={"login.forgotPasswordPlaceholder"} defaultMessage={"Password (8 Minimum)"} />}
              passwordValidator={validator}
              onChange={this.handlePasswordChange}
            />
            <PasswordField
              id="newPasswordConfirm"
              name="newPasswordConfirm"
              label={<FormattedMessage id={"resetPassword.passwordConfirm"} defaultMessage={"Confirm Password"} />}
              match={this.state.newPassword.value}
              passwordValidator={validator}
              onChange={this.handleVerifyPasswordChange}
            />
          </Grid>
        </Grid>
            <br />
        <Grid container className="justify-end">
          <Grid item>
            <Button
              id="cancelResetPassword"
              name="cancelResetPassword"
              onClick={this.handleCloseResetPassword}
              variant="outlined"
              color="primary">
                {<FormattedMessage id={"login.cancel"} defaultMessage={"Cancel"} />}
            </Button>
          </Grid>
          <Grid item >
            <Button
              id="submitResetPassword"
              name="submitResetPassword"
              onClick={this.handleOnSubmit}
              disabled={!this.state.submitEnabled}
              color="primary">
                {<FormattedMessage id={"login.resetPassword"} defaultMessage={"Reset Password"} />
              }</Button>
          </Grid>
        </Grid>
      </main>
    )
  }
}

ForgotPassword.propTypes = {
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  username: PropTypes.string,
  classes: PropTypes.object.isRequired,
  resetPasswordWatcher: PropTypes.func.isRequired,
  cancelResetPasswordWatcher: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPasswordWatcher,
    cancelResetPasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl((ForgotPassword)))