import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { cancelResetPasswordWatcher, resetPasswordWatcher } from "../../redux/actions/loginactioncreators";
import PropTypes from "prop-types";
import challengeShape from "../../proptypes/challengeShape";
import LoaderOverlay from "./../LoaderOverlayMask"
import { Button } from "../fields/Button";
import { TextField } from "../fields/TextField";


export class ResetPasswordForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loginErrorMessages: [],
      validationErrors: {},
      username: null,
      password: null
    }
  }

  handleCloseResetPassword = () => {
    this.props.cancelResetPasswordWatcher()
  }
  handleCodeChange = event => {
    let state = this.state;
    state.code = event.target.value;
    this.setState(state);
  }
  handlePasswordChange = event => {
    let state = this.state;
    state.password = event.target.value;
    this.setState(state);
  }
  handlePasswordConfirmChange = event => {
    let state = this.state;
    state.passwordConfirm = event.target.value;
    this.setState(state);
  }
  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    this.setState(newState);
    const validated = this.validateResetPassword();
    if (validated) {
      this.props.resetPasswordWatcher(
        {
          step: "CONFIRM_RESET_PASSWORD",
          username: this.props.username.trim().toLowerCase(),
          code: this.state.code,
          password: this.state.password.trim()
        }
      )
    }
  }

  validateResetPassword() {
    let state = this.state;
    let valid = false;
    if (!this.state.code) {
      state.validationErrors.code = true;
    } else {
      state.validationErrors.code = false;
      valid = true;
    }
    if (!this.state.password) {
      state.validationErrors.password = true;
    } else {
      state.validationErrors.password = false;
      valid = true;
    }
    if (!this.state.passwordConfirm) {
      state.validationErrors.passwordConfirm = true;
    } else {
      state.validationErrors.passwordConfirm = false;
      valid = true;
    }
    this.setState(state);
    return valid;
  }

  render() {
    const { formatMessage } = this.props.intl;
    const deliveryMedium = this.props.challenge?.challengeParameters?.CodeDeliveryDetails.DeliveryMedium;
    let deliveryMediumText = <FormattedMessage id={"resetPassword.deliveryMediumEmail"} defaultMessage={"email"} />;
    if (deliveryMedium === "SMS") {
      deliveryMediumText = <FormattedMessage id={"resetPassword.deliveryMediumSms"} defaultMessage={"text message"} />;
    }
    const resetPasswordTextClassname = "bg-gray-100";
    return (
      <React.Fragment>

        <Dialog open={this.props.open}>
          <LoaderOverlay>

            <DialogTitle className={resetPasswordTextClassname}>
              <FormattedMessage id={"login.forgotPasswordTitle"} defaultMessage={"Forgot your password?"} />
            </DialogTitle>

            <React.Fragment>
              <DialogContent className={resetPasswordTextClassname}>
                <DialogContentText id="alert-dialog-description">
                  {this.props.challenge.challengeParameters &&
                    <Typography>{formatMessage({
                      id: "login.forgotPasswordBlurb",
                      defaultMessage: "An email with a verification code was sent to the email address associated with your login. If you do not receive a verification code, please contact support at the number provided in the 'Contact Us' link below.",
                    }, {
                      deliveryMedium: deliveryMediumText,
                      destination: this.props.challenge.challengeParameters.CodeDeliveryDetails.Destination
                    })}</Typography>
                  }
                  {deliveryMedium === "EMAIL" &&
                    <Typography>{formatMessage({
                      id: "resetPassword.emailSender",
                      defaultMessage: "Look for an email from {sender}.  You may need to check your spam folder",
                    }, {sender: "noreply@sterling.app"})}</Typography>
                  }
                  <Typography>
                    <FormattedMessage id={"login.forgotPasswordMfaWarning"} defaultMessage={"This will not reset your Multi-Factor Authentication method."} />
                  </Typography>
                </DialogContentText>
                <Grid container className="justify-center">
                  <Grid item xs={12} md={7}>
                    <Typography align="left">

                    </Typography>
                  </Grid>
                  <br />
                  <Grid item xs={12} md={7}>
                    <TextField
                      error={this.state.validationErrors.code}
                      onChange={this.handleCodeChange}
                      id="code"
                      name="code"
                      label={<FormattedMessage id={"resetPassword.codeEntry"} defaultMessage={"Verification Code"} />}
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      type="password"
                      error={this.state.validationErrors.password}
                      onChange={this.handlePasswordChange}
                      id="newPassword"
                      name="newPassword"
                      label={<FormattedMessage id={"login.password"} defaultMessage={"Password"} />}
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid item xs={12} md={7}>
                    <TextField
                      type="password"
                      error={this.state.validationErrors.passwordConfirm}
                      onChange={this.handlePasswordConfirmChange}
                      id="newPasswordConfirm"
                      name="newPasswordConfirm"
                      label={<FormattedMessage id={"resetPassword.passwordConfirm"} defaultMessage={"Confirm Password"} />}
                    />
                  </Grid>
                  <Grid item xs={3}></Grid>
                </Grid>
              </DialogContent>
              <DialogActions className={resetPasswordTextClassname}>
                <Button
                  id="cancelResetPassword"
                  name="cancelResetPassword"
                  onClick={this.handleCloseResetPassword}
                  variant="outlined"
                  color="secondary">
                    <FormattedMessage id={"login.cancel"} defaultMessage={"Cancel"} />
                </Button>
                <Button
                  id="submitResetPassword"
                  name="submitResetPassword"
                  onClick={this.handleOnSubmit}
                  variant="contained"
                  color="primary">
                    <FormattedMessage id={"login.cancel"} defaultMessage={"Cancel"} />
                  </Button>
              </DialogActions>
            </React.Fragment>
          </LoaderOverlay>
        </Dialog>

      </React.Fragment>
    )
  }
}

ResetPasswordForm.propTypes = {
  open: PropTypes.bool,
  username: PropTypes.string,
  classes: PropTypes.object.isRequired,
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  resetPasswordWatcher: PropTypes.func.isRequired,
  cancelResetPasswordWatcher: PropTypes.func.isRequired,
}
const mapStateToProps = state => {
  return {
    username: state.loginForm.username,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge || null
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    resetPasswordWatcher,
    cancelResetPasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ResetPasswordForm));