  /**
   * Returns the client id from the back-end
   * 
   * This should be the id that was provided on the URL, or the default for the pool
   * @returns {string} 
   */
export default function getClientId(state) {
  if (state.loginForm && state.loginForm.clientId) {
    return state.loginForm.clientId;
  }
}