import axios from 'axios';
import getCognitoIdentityCallbacks from "./cognitoIdentityCallbacks";

export const changePassword = (data, options) => {
    return axios.post('/api/password', data, options);
}

export const getProvider = (data, options) => {
    return axios.post('/login/provider', data, options);
}
export const loginWithPassword = (data, options) => {
    return axios.post('/login/password', data, options);
}
// TODO: name
export const initiateCognitoLoginFlow = (cognitoUser, authenticationDetails) => {
    return new Promise((resolve,reject) => {
        return cognitoUser.authenticateUser(
            authenticationDetails,
            getCognitoIdentityCallbacks(resolve, reject)
        );
    });
}

export const forgotPassword = (data, options) => {
    return axios.post('/api/forgotPassword', data, options);
}