import {put, select, takeLatest} from "redux-saga/effects"
import { handleAuthResponse } from "../actions/loginactioncreators"
import { handleError } from "../actions/loginactioncreators";
import getIsPreAuth from "../helpers/getIsPreAuth";
import getCognitoUser from "../helpers/getCognitoUser";
// import getSaveDevice from "../helpers/getSaveDevice";// TODO COR-1814
import actiontypes from "../actions/actiontypes";
import getCognitoIdentityCallbacks from "../../lib/cognitoIdentityCallbacks";

export function* sendSmsMfaCode(action) {
  try {
    const isPreAuth = yield select(getIsPreAuth);
    // TODO COR-1814
    // const saveDevice = yield select(getSaveDevice);
    if (isPreAuth) {
      const cognitoUser = yield select(getCognitoUser);
      const cognitoResponse = yield new Promise((resolve,reject) => {
        return cognitoUser.sendMFACode(
          action.payload,
          getCognitoIdentityCallbacks(resolve, reject)
        )
      });
      yield put(handleAuthResponse(cognitoResponse));
    } 

    // if (saveDevice) {
    //   // TODO COR-1814 implement device key first
    //   // REQUIRES VALID DEVICE KEY TO HAVE BEEN SET ELSEWHERE
    //   yield cognitoUser.setDeviceStatusRemembered({
    //     onSuccess: function(result) { },
    //     onFailure: function(rememberDeviceFailure) {
    //       // TODO decide what to do here
    //     },
    //   });
    // }
  } catch (error) {
    yield put(handleError(error));
  }
}


export function* watchSmsMfaCodeAsync() {
  yield takeLatest(actiontypes.MFA_SEND_SMS_CODE, sendSmsMfaCode);
}
