import {put, select, takeLatest} from "redux-saga/effects"
import { initiateCognitoLoginFlow } from "../../lib/services"
import { handleAuthResponse, handleError, saveCognitoUserObject } from "../actions/loginactioncreators"
import getUsername from "../helpers/getUsername";
import {normalizePayload} from "../helpers/normalizePayload";
import getClientId from "../helpers/getClientId";
import {
	CognitoUserPool,
	AuthenticationDetails,
	CognitoUser,
} from "amazon-cognito-identity-js";
import actiontypes from "../actions/actiontypes";
import getUserPoolId from "../helpers/getUserPoolId";
import getloginClientId from "../helpers/getLoginClientId";

export function* loginAsync(action) {
  try {
    const AmazonCognitoAdvancedSecurityData = window["AmazonCognitoAdvancedSecurityData"];
    const Username = yield select(getUsername);
    const Password = action.payload.password;
    const ClientId = yield select(getloginClientId);
    const actualClientId = yield select(getClientId);
    const UserPoolId = yield select(getUserPoolId);

    const encodedData = AmazonCognitoAdvancedSecurityData.getData(Username, UserPoolId, ClientId);
    let payload = normalizePayload(action.payload);
    payload.userContextData = encodedData;

    const Pool = new CognitoUserPool({UserPoolId, ClientId });
    const ClientMetadata = { actualClientId }
    const authenticationDetails = new AuthenticationDetails({ Username, Password, ClientMetadata });
    const cognitoUser = new CognitoUser({ Username, Pool });
    cognitoUser.setAuthenticationFlowType("USER_PASSWORD_AUTH");
    const loginWithPasswordResponse = yield initiateCognitoLoginFlow(cognitoUser, authenticationDetails);
    // We need this user context stored for MFA, among other reasons
    yield put(saveCognitoUserObject(cognitoUser));
    yield put(handleAuthResponse(loginWithPasswordResponse));
  } catch (error) {
    yield put(handleError(error))
  }
}


export function* watchLoginAsync() {
  yield takeLatest(actiontypes.USER_LOGIN, loginAsync);
}