import React, { Component } from "react";
import { Grid, Typography } from "@material-ui/core";
import { TextField } from "../fields/TextField";
import { Button } from "../fields/Button";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { sendSmsMfaCode } from "../../redux/actions/loginactioncreators";
import SaveDevice from "./SaveDevice";
import ResendVerificationCode from "./ResendVerificationCode";
import challengeShape from "../../proptypes/challengeShape";
import { navigateToLogin } from "../../redux/helpers/navigators";
import PropTypes from "prop-types";

class MfaCodeEntry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      mfaCode: "",
      saveDevice: false,
    }
  }

  handleBackToUserNameEntry = () => {
    navigateToLogin();
  }

  handleOnChange = event => {
    let newState = this.state;
    newState.mfaCode = event.target.value;
    this.setState(newState);
  }

  handleOnSubmit = () => {
    let newState = this.state;
    newState.loginErrorMessages = [];
    this.setState(newState);
    this.props.sendSmsMfaCode(this.state.mfaCode);
  }

  render() {
    const {formatMessage} = this.props.intl;
    const {challenge} = this.props;
    let destination = this.props.challenge
      && challenge.challengeParameters
      && challenge.challengeParameters.CODE_DELIVERY_DESTINATION
      || "";
    return (
      <main>
        <Grid item xs={12}>
          <Grid container className='justify-start'>
            <Grid item xs={12}>
              <Typography variant="h6">
                <FormattedMessage id={"mfa.enterCode"} defaultMessage={"Enter code"} />
              </Typography>
              <Typography>
                {formatMessage({
                  id: "sms.destination",
                  defaultMessage: "Type in the code sent to SMS number: {destination}",
                }, {destination})}
              </Typography>
            </Grid>
          </Grid>
          <Grid container className='justify-start'>
            <Grid item xs={12}>
              <ResendVerificationCode/>
            </Grid>
          </Grid>
          <Grid container className='justify-start'>
            <Grid item xs={12}>
              <TextField
                autoFocus={true}
                onChange={this.handleOnChange}
                onSubmit={this.handleOnSubmit}
                id="mfacode"
                name="mfacode"
                label={<FormattedMessage id={"sms.codeEntryText"} defaultMessage={"Verification Code"} />}
                fullWidth={true}
              />
            </Grid>
          </Grid>

          <Grid container className='justify-start'>
            <Grid item xs={12}>
              <SaveDevice/>
            </Grid>
          </Grid>
          <Grid container className="justify-end">
            <Grid item >
              <Button
                id="backToUserNameEntry"
                name="backToUserNameEntry"
                onClick={this.handleBackToUserNameEntry}
                variant="outlined"
                className="font-bold"
              >
                <FormattedMessage id={"login.backToUserNameEntry"} defaultMessage={"Back"} />
              </Button>
            </Grid>
            <Grid item>
              <Button
                id="submit"
                name="submitVerify"
                onClick={this.handleOnSubmit}
                disabled={this.state.mfaCode.length !== 6}
              >
                <FormattedMessage id={"sms.verifyButton"} defaultMessage={"Verify"} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </main>
    )
  }
}

MfaCodeEntry.propTypes = {
  challenge: challengeShape,
  intl: PropTypes.objectOf(IntlProvider),
  sendSmsMfaCode: PropTypes.func.isRequired,
  username: PropTypes.string,
  password: PropTypes.string,
  complete: PropTypes.bool,
  saveDevice: PropTypes.bool,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    challenge: state.loginForm.challenge,
    deviceKey: state.loginForm.deviceKey,
    password: state.loginForm.password,
    username: state.loginForm.username,
    saveDevice: state.loginForm.saveDevice
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    sendSmsMfaCode
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MfaCodeEntry));