import React, { Component } from "react";
import { connect } from "react-redux";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { bindActionCreators } from "redux";
import { removeProvider } from "../../redux/actions/loginactioncreators";
import PropTypes from "prop-types";
import providerShape from "../../proptypes/providerShape";
import { Button } from "../fields/Button";

export class BackToUserNameEntry extends Component {
  handleBackToUserNameEntry = () => {
    this.props.removeProvider();
  }
  render() {
    return (
      <React.Fragment>
        {this.props.provider &&
          <Button
            id="backToUserNameEntry"
            name="backToUserNameEntry"
            onClick={this.handleBackToUserNameEntry}
            variant="outlined"
            disabled={!this.props.provider}
          >
            <FormattedMessage id={"login.backToUserNameEntry"} defaultMessage={"Back"} />
          </Button>
        }
      </React.Fragment>
    )
  }
}

BackToUserNameEntry.propTypes = {
  removeProvider: PropTypes.func.isRequired,
  intl: PropTypes.objectOf(IntlProvider),
  provider: providerShape
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    username: state.loginForm.username,
    provider: state.loginForm.provider
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    removeProvider
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BackToUserNameEntry));
