import React, { Fragment, Component} from "react";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { Grid, Typography } from "@material-ui/core";
import { navigateToLogout, navigateToApplication } from "../../redux/helpers/navigators";
import PropTypes from "prop-types";
import {BusyButton} from "@sterling-react/button"
import { twMerge } from "tailwind-merge";

export class ExistingSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      signOutBusy: false,
      staySignedInBusy: false,
    }
    this.secondsBeforeRetryAvailable = 10;
  }

  handleNavigateToApplication = () => {
    navigateToApplication();
  }
  handleNavigateToLogout = () => {
    navigateToLogout();
  }
  setSignOutBusyForSeconds = (seconds) => {
    this.setState({...this.state, signOutBusy: true});
    setTimeout(() => this.setState({...this.state, signOutBusy: false}), seconds * 1000);
  }
  setStaySignedInBusyForSeconds = (seconds) => {
    this.setState({...this.state, staySignedInBusy: true});
    setTimeout(() => this.setState({...this.state, staySignedInBusy: false}), seconds * 1000);
  }
  render() {
    const bespokeExistingSessionModalButton = "btn mr-2 px-2.5 py-1 font-sans tracking-wide";
    const bespokeNonDefaultExistingSessionModalButton = twMerge(bespokeExistingSessionModalButton, "btn-outlined");
    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography id="existingSessionMessage">{
              <FormattedMessage id={"login.existingSession"} defaultMessage={"This username is already in use. Would you like to disconnect them so you may stay signed in?"} />}</Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container className="justify-end">
          <Grid item>
            <BusyButton
              id="existingSessionSignOut"
              onClick={() => {
                this.setSignOutBusyForSeconds(this.secondsBeforeRetryAvailable);
                this.handleNavigateToLogout();
              }}
              busy={this.state.signOutBusy}
              className={bespokeNonDefaultExistingSessionModalButton}
            >
              <FormattedMessage id={"login.signOut"} defaultMessage={"Sign out"} />
            </BusyButton>
          </Grid>
          <Grid item>
            <BusyButton
              id="existingSessionStaySignedIn"
              onClick={() => {
                this.setStaySignedInBusyForSeconds(this.secondsBeforeRetryAvailable);
                this.handleNavigateToApplication();
              }}
              busy={this.state.staySignedInBusy}
              className={bespokeExistingSessionModalButton}
            >
              <FormattedMessage id={"login.staySignedIn"} defaultMessage={"Stay signed in"} />
            </BusyButton>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}

ExistingSession.propTypes = {
  intl: PropTypes.objectOf(IntlProvider),
  productName: PropTypes.string,
}

export default injectIntl(ExistingSession);