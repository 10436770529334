import actiontypes from "../actions/actiontypes";
import { handleResetPasswordWatcher, loginWithPasswordWatcher, handleError } from "../actions/loginactioncreators";
import { put, takeLatest, select } from "redux-saga/effects"
import { forgotPassword } from "../../lib/services"
import getLocale from "../helpers/getLocale";
import getClientId from "../helpers/getClientId";

export function* resetPasswordAsync(action) {
  try {
    let payload = action.payload;
    const clientId = yield select(getClientId);
    payload.clientId = clientId;
    const locale = yield select(getLocale);
    const options = {
      headers: {
        "Accept-Language": locale,
      }
    };
    let { data } = yield forgotPassword(payload, options);
    if (data?.status === "success") {
      // Attempt login if password changed
      yield put(loginWithPasswordWatcher({username: payload.username, password: payload.password}))
    } else {
      yield put(handleResetPasswordWatcher(data));
    }
  } catch (error) {
    yield put(handleError(error));
  }
}

export function* watchResetPasswordAsync() {
  yield takeLatest(actiontypes.USER_RESET_PASSWORD, resetPasswordAsync);
}