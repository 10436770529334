export default function getDeviceData(state, username) {
  if (username && (typeof username === 'string' || username instanceof String)) {
    const normalizedUsername = username.toLowerCase();
    if (state.loginForm && state.loginForm.device) {
      if (state.loginForm.device[normalizedUsername]) {
        return state.loginForm.device[normalizedUsername];
      }
    }
  }
  return {};
}