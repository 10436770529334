import React, { Fragment, Component } from "react";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import { Grid, Typography } from "@material-ui/core";
import { Button } from "../fields/Button";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { navigateToApplication, navigateToDashboard } from "../../redux/helpers/navigators";
import PropTypes from "prop-types";

export class LoginFirstTime extends Component {

  handleNavigateToDashboard = () => {
    navigateToDashboard();
  }
  handleNavigateToApplication = () => {
    navigateToApplication();
  }
  render() {
    const { formatMessage } = this.props.intl;

    return (
      <Fragment>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6">
              <FormattedMessage id={"login.firstTimeSuccess"} defaultMessage={"Success!"} />
            </Typography>
            <Typography>{formatMessage({
              id: "login.firstTimeBlurb",
              defaultMessage: "You may continue directly into your secure account or add another Sterling account in your Account Dashboard.",
            }, { product: this.props.productName })}
            </Typography>
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container className="justify-end">
          <Grid item>
            <Button
              id="goToDashboard"
              onClick={this.handleNavigateToDashboard}
              variant="outlined"
            >
              <FormattedMessage id={"login.firstTimeContinueToDashboard"} defaultMessage={"Add Account"} />
            </Button>
          </Grid>
          <Grid item>
            <Button
              id="autoLaunch"
              onClick={this.handleNavigateToApplication}
              autoFocus={true}
            >
              <FormattedMessage id={"login.firstTimeAutoLaunch"} defaultMessage={"Continue"} />
            </Button>
          </Grid>
        </Grid>
      </Fragment>
    )
  }
}
LoginFirstTime.propTypes = {
  intl: PropTypes.objectOf(IntlProvider),
  productName: PropTypes.string,
}
const mapStateToProps = state => {
  return {
    loginErrorMessages: state.loginForm.loginErrorMessages,
    username: state.loginForm.username,
    provider: state.loginForm.provider,
    productName: state.loginForm.productName
  }
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LoginFirstTime));