import { connect } from "react-redux";
import CircularProgress from "@sterling-react/circular-progress";
import { Backdrop } from "@material-ui/core";
import { Component } from "react";

class LoadingOverlayWrapper extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    const bespokeBackdropWrapperClassname = "z-10 relative";
    return (
      <div>
        <div className={bespokeBackdropWrapperClassname}>
          <Backdrop
            open={this.props.active}
          >
            <CircularProgress thickness={2} size={50} />
          </Backdrop>
        </div>
        {this.props.children}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  active: state.loginForm.isFetching ?? false,
});


export default connect(mapStateToProps)(LoadingOverlayWrapper);