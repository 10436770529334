import actiontypes from "../actions/actiontypes";
import {handleAuthResponse, handleChangePasswordWatcher, handleError, loginWatcher} from "../actions/loginactioncreators";
import {put, takeLatest, select} from "redux-saga/effects"
import getLocale from "../helpers/getLocale";
// TODO COR-1823
// import getAuthToken from "../helpers/getAuthToken";
import getCognitoUser from "../helpers/getCognitoUser";
import getFlowStage from "../helpers/getFlowStage";
import getChallenge from "../helpers/getChallenge";
import getUserAttributes from "../helpers/getUserAttributes";
import getUsername from "../helpers/getUsername";

export function* changePasswordAsync(action) {
  try {
    const flowStage = yield select(getFlowStage);
    const challenge = yield select(getChallenge);
    const username = yield select(getUsername);
    // COR-1823 A token isn't yet required for this operation
    // const authToken = yield select(getAuthToken);

    if (flowStage === "EXPIRED_NEW_PASSWORD_REQUIRED") {
      const legacyChangePasswordPayload = {
        flowStage: flowStage,
        challengeResponse: {
          session: challenge.session,
          challengeName: challenge.challengeName,
          challengeParameters: {
            USERNAME: challenge.challengeParameters.USER_ID_FOR_SRP,
            OLD_PASSWORD: action.payload.oldPassword.trim(),
            NEW_PASSWORD: action.payload.newPassword.trim()
          }
        },
        username: username,
      };
      const locale = yield select(getLocale);
      const options = {
        headers: {
          // TODO COR-1823
          // "Authorization": authToken,
          "Accept-Language": locale,
        }
      };
      let {data} = yield put(loginWatcher(legacyChangePasswordPayload, options));

      yield put(handleChangePasswordWatcher(data));
    } else {
      const cognitoUser = yield select(getCognitoUser);
      const UserAttributes = yield select(getUserAttributes);

      const completeNewPasswordChallengeResponse = yield new Promise((resolve,reject) => {
        cognitoUser.completeNewPasswordChallenge(
          action.payload.newPassword,
          UserAttributes,
          {
              onSuccess: resolve,
              onFailure: reject
          }
      )});

      yield put(handleAuthResponse(completeNewPasswordChallengeResponse));
    }
  } catch (error) {
    yield put(handleError(error));
  }

}

export function* watchChangePasswordAsync() {
  yield takeLatest(actiontypes.USER_CHANGE_PASSWORD, changePasswordAsync);
}