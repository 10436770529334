import React, { Component } from "react";
import { FormattedMessage, IntlProvider, injectIntl } from "react-intl";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { connect } from "react-redux";

class OptOut extends Component {
  render() {
    const optOutUrl = this.props.classicLoginExperienceUrl + "&username=" + encodeURIComponent(this.props.username)
    return (
      <Typography id="optOutBlurb">
        <FormattedMessage
          id="login.optOutBlurbBegin"
          values={{
            serviceProviderName: this.props.serviceProviderName,
            classicLoginExperienceUrl: optOutUrl
          }}
          defaultMessage="Having trouble logging in?  Click"
        />
        &nbsp;
        <a id="optOutLink" href={optOutUrl}>
          <FormattedMessage
            id="login.optOutBlurbLink"
            defaultMessage="here"
          />
        </a>
        &nbsp;
        <FormattedMessage
          id="login.optOutBlurbEnd"
          defaultMessage="to use the classic login experience."
        />
      </Typography>
    )
  }
}

OptOut.propTypes = {
  serviceProviderName: PropTypes.string.isRequired,
  classicLoginExperienceUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  intl: PropTypes.objectOf(IntlProvider)
}

const mapStateToProps = state => {
  return {
    serviceProviderName: state.loginForm.serviceProviderName,
    classicLoginExperienceUrl: state.loginForm.classicLoginExperienceUrl,
    username: state.loginForm.username,
  }
};
export default connect(mapStateToProps)(injectIntl(OptOut));