import * as queryString from "query-string";

export class ProviderDao {
    constructor(client_id) {
        this.client_id = client_id;
        this.providerKey = `lastProvider`;
        this.parsedQueryString = queryString.parse(window.location.search);
    }

    checkAutoLaunchProvider(providerData) {
        if (
            providerData?.autoLaunch &&
            this.parsedQueryString?.logoutComplete !== "true" &&
            providerData.idpIdentifier
        ) {
            return true;
        }
    }

    initProvider(provider) {
        if (this.parsedQueryString?.clearProvider !== undefined) {
            this.clearLastLoginProvider();
            return;
        }
        try {
            const savedProvider = this.getSavedProvider();
            if (savedProvider?.idpIdentifier) {
                return;
            }
            this.saveLastLoginProvider(provider, false);
        } catch (error) {
            // Couldn't get a provider
        }
    }

    getSavedProvider() {
        let savedProvider;
        try {
            savedProvider = JSON.parse(localStorage.getItem(this.providerKey));
        } catch (error) {
            localStorage.clear(this.providerKey);
        }
        return savedProvider;
    }

    saveLastLoginProvider(lastProvider, autoLaunch) {
        if (!lastProvider?.idpIdentifier) {
            return
        }
        const savedProvider = this.getSavedProvider();
        if (savedProvider?.deleted !== lastProvider.idpIdentifier) {
            localStorage.setItem(
                this.providerKey,
                JSON.stringify({ type: lastProvider.type, idpIdentifier: lastProvider.idpIdentifier, autoLaunch})
            );
        }
    }

    deleteLastLoginProvider() {
        this.clearLastLoginProvider();
        window.location = "/?clearProvider=true";
    }

    clearLastLoginProvider() {
        localStorage.removeItem(this.providerKey);
    }

    launchProvider(provider) {
        let location = provider.url ?? `/?provider=${provider.idpIdentifier}&autologin=true`;
        const parsedQueryString = queryString.parse(window.location.search);
        if (parsedQueryString.state) {
            location += "&state=" + parsedQueryString.state;
        }
        if (this.client_id) {
            location += "&client_id=" + this.client_id;
        }
        if (parsedQueryString.nonce) {
            location += "&nonce=" + parsedQueryString.nonce;
        }
        window.location = location;
    }
}
