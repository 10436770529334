const flowStages =  {
  AUTHENTICATED: 'AUTHENTICATED',
  AUTHENTICATED_FIRST_TIME: 'AUTHENTICATED_FIRST_TIME',
  CHALLENGE: 'CHALLENGE',
  CHALLENGE_MFA_SETUP: 'CHALLENGE_MFA_SETUP',
  RESEND_SMS_VERIFICATION_CODE: 'RESEND_SMS_VERIFICATION_CODE',
  VERIFY_EMAIL: 'VERIFY_EMAIL',
  EMAIL_NOT_VERIFIED: 'EMAIL_NOT_VERIFIED',
  CHANGE_EMAIL_ADDRESS: 'CHANGE_EMAIL_ADDRESS',
  SKIP_EMAIL_NOT_VERIFIED: 'SKIP_EMAIL_NOT_VERIFIED',
  EXPIRED_NEW_PASSWORD_REQUIRED: 'EXPIRED_NEW_PASSWORD_REQUIRED',
}
export default flowStages;