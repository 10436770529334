import TextBox from "@sterling-react/textbox";
import PropTypes from "prop-types";
import { Component } from "react";
import {twMerge} from "tailwind-merge";
export class TextField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validationErrors: [],
        };
    }
    
    formSubmit = (event) => {
        event.preventDefault(); 
        event.stopPropagation();
        if (typeof this.props.onSubmit === "function") {
            this.props.onSubmit(event);
        }
    }

    render() {
        const bespokeLabelClassname = "peer-placeholder-shown:scale-100 peer-placeholder-shown:text-gray-600 duration-200";
        const mergedLabelClassname = twMerge(bespokeLabelClassname, this.props.labelProps?.className);
        const bespokeWrapperClassName = "transition duration-900 mb-1 focus-within:border-b-primary-500";
        const mergedWrapperClassname = twMerge(bespokeWrapperClassName, this.props.wrapperClassName);
    
        return (
            <form onSubmit={this.formSubmit}>
                <TextBox
                    {...this.props}
                    // Explicit props should be last to properly override if necessary
                    labelProps={{ className: mergedLabelClassname}}
                    wrapperClassName={mergedWrapperClassname}
                />
            </form>
        )
    }
}

TextField.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    labelProps: PropTypes.object,
    className: PropTypes.string,
    error: PropTypes.bool,
    helperText: PropTypes.string,
    errorText: PropTypes.string,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({current: PropTypes.object})]),
    adornment: PropTypes.node,
    placeholder: PropTypes.string,
    wrapperClassName: PropTypes.string
  };