// todo: consolidate this form and the one in react-sso-landing

import React, {Component} from 'react';
import ChangePasswordForm from './changePasswordForm/ChangePasswordForm'
import CognitoPasswordValidator from "./changePasswordForm/CognitoPasswordValidator";
import {connect} from "react-redux";
import {changePasswordWatcher} from "../redux/actions/loginactioncreators";
import {bindActionCreators} from "redux";
import PropTypes from 'prop-types';

export class ChangePassword extends Component {
  handleSubmit = (params) => {
    this.props.changePasswordWatcher({
      oldPassword: params.oldPassword.trim(),
      newPassword: params.newPassword.trim()
    });
  }
  render() {
    return (
      <ChangePasswordForm
        onSubmit={this.handleSubmit}
        passwordValidator={
          new CognitoPasswordValidator(8, 99, true, true, true, true)
        }
      />
    )
  }
}

ChangePassword.propTypes = {
  changePasswordWatcher: PropTypes.func.isRequired,
}


const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    changePasswordWatcher
    // add other watcher sagas to this object to map them to props
  }, dispatch);
}

export default connect(null, mapDispatchToProps)(ChangePassword);