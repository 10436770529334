import React, { Component } from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";

class Header extends Component {
  render() {
    const logoUrl = this.props.logoUrl;
    const bespokeLogoClassname = "max-h-full max-w-full my-6";
    return (
      <Grid container>
        <Grid item xs={9} className="max-h-40">
          <img alt="Sterling logo" aria-label='Sterling Logo' src={logoUrl} className={bespokeLogoClassname} />
        </Grid>
      </Grid>
    )
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  logoUrl: PropTypes.string.isRequired,
}
export default Header;