import React, {Component, Fragment} from "react";
import {IntlProvider} from "react-intl";
import {connect} from "react-redux";
import LoginForm from "./login/LoginForm";
import MfaCodeEntry from "./mfa/MfaCodeEntry";
import MfaSetup from "./mfa/MfaSetup"
import MfaChoice from "./mfa/MfaChoice";
import MfaTotpCodeEntry from "./mfa/MfaTotpCodeEntry"
import Paper from "@sterling-react/paper";
import locales from "../i18n/locales";
import Footer from "./Footer";
import ChangePassword from "./ChangePassword";
import LoginFirstTime from "./login/LoginFirstTime";
import ForgotPassword from "./login/ForgotPassword";
import ExistingSession from "./login/ExistingSession";
import ChangeEmailAddress from "./email/ChangeEmailAddress";
import ConfirmEmailAddress from "./email/ConfirmEmailAddress";
import SkipChangeEmailAddress from "./email/SkipChangeEmailAddress";
import PropTypes from "prop-types";
import ForcedResetPassword from "./login/forcedPasswordReset";
import { ProviderDao } from "../redux/helpers/launchProvider";
import * as queryString from "query-string";
import CircularProgress from "@sterling-react/circular-progress";

class Login extends Component {

  render() {
    let locale = this.props.locale || this.props.defaultLocale;
    let localeData;
    if (locales[locale]) {
      localeData = locales[locale].localeData;
      console.log(`OI: ${JSON.stringify(localeData)}`);
    } else {
      locale = "en-us";
      localeData = locales[locale].localeData;
      console.log(`BOO: ${JSON.stringify(localeData)}`);
    }
    this.props.loginErrorMessages.forEach((loginErrorMessage) => {
      this.props.enqueueSnackbar(loginErrorMessage, {variant: "error"});
    });
    this.props.successMessages.forEach((loginMessage) => {
      this.props.enqueueSnackbar(loginMessage, {variant: "success"});
    });

    let flowStage = this.props.flowStage;

    const providerDao = new ProviderDao(this.props.client_id);
    const savedProvider = providerDao.getSavedProvider();
    if (
      this.props.enableAutoIdpRedirect === true &&
      providerDao.checkAutoLaunchProvider(savedProvider) === true
    ) {
      flowStage = "REDIRECT_TO_IDP";
    }

    const parsedQueryString = queryString.parse(window.location.search);
    if (
      parsedQueryString.existingSession &&
      parsedQueryString.existingSession === "true"
    ) {
      flowStage = "AUTHENTICATED_CONFIRM_SINGLE_SESSION";
    }

    let LoginComponent;
    // flowStage = "AUTHENTICATED_CONFIRM_SINGLE_SESSION"
    switch (flowStage) {
      case "MFA_SETUP":
        LoginComponent = MfaSetup;
        break;
      case "SMS_MFA":
        LoginComponent = MfaCodeEntry;
        break;
      case "SOFTWARE_TOKEN_MFA":
        LoginComponent = MfaTotpCodeEntry;
        break;
      case "SELECT_MFA_TYPE":
        LoginComponent = MfaChoice;
        break;
      case "NEW_PASSWORD_REQUIRED":
        LoginComponent = ChangePassword;
        break;
      case "EXPIRED_NEW_PASSWORD_REQUIRED":
        LoginComponent = ChangePassword;
        break;
      case "AUTHENTICATED_FIRST_TIME":
        LoginComponent = LoginFirstTime;
        break;
      case "CONFIRM_RESET_PASSWORD":
        if (this.props.s1TransportUser === true) {
          LoginComponent = ForcedResetPassword;
        } else {
          LoginComponent = ForgotPassword;
        }
        break;
      case "EMAIL_NOT_VERIFIED":
        LoginComponent = ChangeEmailAddress;
        break;
      case "VERIFY_EMAIL":
        LoginComponent = ConfirmEmailAddress;
        break;
      case "SKIP_EMAIL_NOT_VERIFIED":
        LoginComponent = SkipChangeEmailAddress;
        break;
      case "AUTHENTICATED_CONFIRM_SINGLE_SESSION":
        LoginComponent = ExistingSession;
        break;
      case "REDIRECT_TO_IDP":
        providerDao.launchProvider(savedProvider);
        LoginComponent = ()=>(<div className="py-10"> <CircularProgress size={75} /> </div> );
        break;
        case "LOGIN":
          default:
            LoginComponent = LoginForm;
            break;
          }
    const bespokeBaseLayout = "w-[25rem] mt-24 mx-auto";
    const bespokeLoginComponentContainer = "items-center bg-inherit text-primary-700";      
    return (
      <Fragment>
        <main className={bespokeBaseLayout}>
          <IntlProvider locale={locale} messages={localeData}>
            <Fragment>
              <Paper elevation={0} className={bespokeLoginComponentContainer}>
                {this.props.header ? this.props.header : null}
                <LoginComponent/>
              </Paper>
              <br/>
              <Footer/>
            </Fragment>
          </IntlProvider>
        </main>
      </Fragment>
    )
  }
}

Login.propTypes = {
  locale: PropTypes.string,
  defaultLocale: PropTypes.string,
  loginErrorMessages: PropTypes.array,
  enqueueSnackbar: PropTypes.func.isRequired,
  successMessages: PropTypes.array,
  flowStage: PropTypes.string,
  classes: PropTypes.object.isRequired,
  header: PropTypes.func.isRequired,
  s1TransportUser: PropTypes.bool,
  client_id: PropTypes.string,

}
const mapStateToProps = state => {
  return {
    locale: state.intl.locale,
    successMessages: state.loginForm.successMessages,
    enableAutoIdpRedirect: state.loginForm.enableAutoIdpRedirect,
    loginErrorMessages: state.loginForm.loginErrorMessages,
    flowStage: state.loginForm.flowStage,
    s1TransportUser: state.loginForm.s1TransportUser,
    client_id: state.loginForm.clientId,
    wholeState: state
  }
};

export default connect(
  mapStateToProps
)(Login)