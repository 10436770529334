/* eslint-disable no-console */
import actiontypes from "../actions/actiontypes";
import { put, takeLatest } from "redux-saga/effects"
import { setLoginError, handleSetLoginPasswordErrors } from "../actions/loginactioncreators";

//TODO rename?
const errorMap = {
  "Missing required parameter PASSWORD": {
    id: "NotAuthorizedException", 
    defaultMessage:"User does not exist or could not be authenticated",
  },
  "Network error": {
    id: "unknownErrorException",
    defaultMessage: "An unknown error has occurred, please try again later.",
  },
  "Invalid code received for user": {
    id: "codeMismatchException",
    defaultMessage: "Invalid authorization code.",
  },
  "Missing required parameter SOFTWARE_TOKEN_MFA_CODE": {
    id: "codeMismatchException",
    defaultMessage: "Invalid authorization code.",
  }
}
//TODO rename?
const belowPasswordBlank = {
  "Incorrect username or password.": {
    id: "NotAuthorizedException",
    defaultMessage: "User does not exist or could not be authenticated",
  },
  "Password attempts exceeded": {
    id: "tooManyFailedAttemptsException",
    defaultMessage: "Your account has been temporarily locked due to multiple invalid login attempts, and may take up to 15 minutes to automatically unlock",
  }
}

const triggerReload = {
  "Invalid session for the user, session is expired.": {
    id: "codeMismatchException",
    defaultMessage: "Invalid authorization code.",
  }, // This error happens when an MFA session has expired and there is no recourse except to login again.
}

export function* handleError(action) {
  const error = action.payload;
  console.debug({error})
  try {
     if (triggerReload[error.message]) {
      yield put(setLoginError([triggerReload[error.message].defaultMessage]));
      // Tell the browser to reload the page from cache
      window.location.reload();
    } else if (error.response && error.response.data && error.response.data.reasonMessage) {
      // Legacy error handling, translated errors should be returned
      yield put(setLoginError([error.response.data.reasonMessage]))
    } else if (belowPasswordBlank[error.message]) {
      // Error messages to appear and persist under the password field
      // These will pass through error formatter and be translated
      yield put(handleSetLoginPasswordErrors([{message: belowPasswordBlank[error.message]}]))
    } else if (errorMap[error.message]) {
      // TODO get translations working, currently passing strings into the store
      yield put(setLoginError([errorMap[error.message].defaultMessage]))
    /* COR-5427 - Refactoring I18n code, this else case might be redundant.
    } else if (messages[error.code]) {
      // TODO get translations working, currently passing strings into the store
      yield put(setLoginError([messages[error.code].defaultMessage]))
    */
    } else {
      console.error("Unknown Error Encountered");
      console.error(error);
      // TODO get translations working, currently passing strings into the store
      yield put(setLoginError(["An error that hasn't been documented and/or translated"]))
      // TODO may be a good idea to collect these in Google analytics or elsewhere 
    }
  } catch (e) {
    console.error("Unexpected error in handleError");
    console.error(e);
  }
}

export function* handleErrorWatcher() {
  yield takeLatest(actiontypes.HANDLE_ERROR, handleError);
}